import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../Enums/Permissions';

@Injectable()
export class CarrierFileManagerGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    const result = this.authService.can(Permissions.ViewCarrierFile);

    if (result) {
      return true;
    }

    this.authService.redirectNotAuthorized();
    return false;
  }
}
