import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TrackingPreference } from '../models/TrackingPreference';
import { RefTrackingPreference } from '../models/RefTrackingPreference';
import { Configuration } from '../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';

@Injectable()
export class TrackingPreferenceService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<TrackingPreference> {
    return this.http.get<TrackingPreference>(
      `${this.config.carrierServiceUrl}${carrierCode}/TrackingPreference`,
    );
  }

  save(
    carrierCode: string,
    trackingPreference: TrackingPreference,
  ): Observable<TrackingPreference> {
    return this.http.put<TrackingPreference>(
      `${this.config.carrierServiceUrl}${carrierCode}/TrackingPreference`,
      trackingPreference,
    );
  }

  getRefTrackingPreferences(): Observable<RefTrackingPreference[]> {
    return this.http.get<RefTrackingPreference[]>(
      `${this.config.carrierServiceUrl}reference/tracking`,
    );
  }
}
