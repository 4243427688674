import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'truckload-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss'],
})
export class HealthComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
