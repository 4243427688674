import { Component, OnInit, Input } from '@angular/core';
import { InspectionService } from './inspection.service';
import { CommonService } from '../../../Services/common.service';
import { CarrierInspection } from './CarrierInspection';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'truckload-carrier-inspections',
  templateUrl: './carrier-inspections.component.html',
  styleUrls: ['./carrier-inspections.component.scss'],
})
export class CarrierInspectionsComponent implements OnInit {
  @Input()
  carrierCode: string;

  carrierInspections: CarrierInspection[];

  constructor(
    public inspectionService: InspectionService,
    public commonService: CommonService,
  ) {}

  ngOnInit() {
    this.inspectionService
      .getInspections(this.carrierCode)
      .subscribe((data) => {
        this.carrierInspections = data;
      });
  }
}
