import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { TruckPosting } from '../../models/TruckPosting';
import { CarrierTruckPostingService } from '../../services/carrier-truck-posting.service';
import { TruckPost, TruckPostAddress } from '../../models/TruckPost';
import { RefActivity } from '../../../../shared/references/RefActivity';
import { RefDisposition } from '../../../../shared/references/RefDisposition';
import { TruckPostingDefaults } from '../../../carrier-file/carrier-defaults/TruckPostingDefaults';
import { State } from '@progress/kendo-data-query';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { Permissions } from '../../../../shared/Enums/Permissions';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CarrierTruckPostingEditComponent } from '../carrier-truck-posting-edit/carrier-truck-posting-edit.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../appstate.model';
import * as TruckPostActions from '../../actions/truck-post.actions';

@Component({
  selector: 'truckload-carrier-truck-posting',
  templateUrl: './carrier-truck-posting.component.html',
  styleUrls: ['./carrier-truck-posting.component.scss'],
})
export class CarrierTruckPostingComponent implements OnInit {
  selectedAddress: TruckPostAddress = {} as TruckPostAddress;
  selectedAddressChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('autocomplete', { static: true })
  public autocomplete: ComboBoxComponent;
  @ViewChild('truckPostingAddNew', { static: true })
  public truckPostingAddNew: ElementRef;
  public originMatches: Array<TruckPostAddress> = [];
  public destinationMatches: Array<TruckPostAddress> = [];
  public steps: any = { minute: 15 };
  public min: Date = this.getMinDate();

  itemToRemove: TruckPost;
  truckPosting: TruckPosting;
  legalName: string;
  carrierCode: string;
  showNoTruck = false;
  disableNoTruck = false;
  postingGridData: TruckPost[];
  errorMessage: string;
  defaults: TruckPostingDefaults;
  activityList: RefActivity[];
  dispositionList: RefDisposition[];
  currentEditingPost: TruckPost;
  public isNew: boolean;
  public loading: boolean;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  constructor(
    private truckPostService: CarrierTruckPostingService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dialogService: DialogService,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.TruckPost.loading)
      .subscribe((x) => {
        this.loading = x;
      });

    this.store
      .select((x) => x.TruckPost.loadingError)
      .subscribe((x) => {
        this.errorMessage = x;
      });

    this.store
      .select((x) => x.TruckPost)
      .subscribe((x) => {
        this.itemToRemove = null;
        (this.truckPosting = x.truckPosting),
          (this.truckPosting = x.truckPosting);
        this.showNoTruck = this.truckPosting.noTruck;
        this.postingGridData = this.truckPosting.truckPostings;
        this.defaults = x.postDefaults;
        this.activityList = x.activityTypes;
        this.dispositionList = x.dispositionsTypes;
        if (x.truckPosting.truckPostings.length > 0) {
          this.disableNoTruck = true;
        }
        if (x.truckPosting.truckPostings.length <= 0) {
          this.disableNoTruck = false;
        }
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
    });
  }

  get canEditPostings(): boolean {
    return this.authService.can(Permissions.EditPostings);
  }

  createNoTruckActivity() {
    this.truckPostService.noTruck(this.carrierCode).subscribe((response) => {
      this.showNoTruck = response.noTruck;
    });
  }

  public removeHandler({ dataItem }): void {
    this.itemToRemove = dataItem;
  }

  public editHandler({ dataItem }): void {
    this.isNew = false;
    this.currentEditingPost = dataItem;
    const dialog: DialogRef = this.dialogService.open({
      width: 950,
      content: CarrierTruckPostingEditComponent,
    });
    dialog.content.instance.truckPost = this.currentEditingPost;
    dialog.content.instance.isNew = this.isNew;
    dialog.content.instance.carrierCode = this.carrierCode;
  }

  public addNewPostHandler() {
    this.isNew = true;
    this.currentEditingPost = null;
    const dialog: DialogRef = this.dialogService.open({
      width: 950,
      content: CarrierTruckPostingEditComponent,
    });
    dialog.content.instance.truckPost = this.currentEditingPost;
    dialog.content.instance.isNew = this.isNew;
    dialog.content.instance.carrierCode = this.carrierCode;
  }

  private confirmRemove(remove: boolean) {
    if (!remove) {
      this.itemToRemove = null;
      return;
    }
    this.store.dispatch(
      new TruckPostActions.DeleteTruckPost(this.carrierCode, this.itemToRemove),
    );
  }

  public getMinDate() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  private onStateChange(state: State) {
    this.gridState = state;
  }

  private cancelHandler() {
    this.currentEditingPost = undefined;
  }
}
