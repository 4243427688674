import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';
import { Configuration } from '../configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';

@Injectable()
export class CarrierTruckTypeService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    store: Store<AppState>,
  ) {
    store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  @Cacheable()
  getCarrierTruckTypes(
    carrierCode: string,
  ): Observable<CarrierTruckTypeInventory> {
    return this.http.get<CarrierTruckTypeInventory>(
      `${this.config.carrierServiceUrl}${carrierCode}/inventory`,
    );
  }
}

export interface CarrierTruckTypeInventory {
  truckCount: number;
  driverCount: number;
  inventory: Array<TruckTypeInventory>;
}
export interface TruckTypeInventory {
  inventory: number;
  truckType: string;
  code: string;
}
