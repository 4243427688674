import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../Enums/Permissions';

@Injectable()
export class FUMCarrierFileManagerGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    // enable the following line, prior to merging this PR
    //const result = this.authService.can(Permissions.ViewFUMCarrierFile);
    const result = this.authService.can(Permissions.ViewFUMCarrierFile);

    if (result) {
      return true;
    }

    this.authService.redirectNotAuthorized();
    return false;
  }
}
