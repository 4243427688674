import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierCertification } from '../models/CarrierCertification';
import { FUMSaveCarrierCertifications } from '../models/FUMSaveCarrierCertifications';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { UpdateCarrierCertification } from '../models/UpdateCarrierCertifications';

@Injectable()
export class FumCarrierCertificationService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<CarrierCertification[]> {
    return this.http.get<CarrierCertification[]>(
      `${this.config.carrierServiceUrl}fum/carrierCertification?carrierCode=${carrierCode}`
    );
  }

  save(
    updateCarrierCertification: UpdateCarrierCertification,
  ): Observable<number> {
    return this.http.put<number>(
      `${this.config.carrierServiceUrl}fum/carrierCertification`,
      updateCarrierCertification,
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  }
}
