import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { AppUser } from '../appuser-autocomplete/AppUser';

@Component({
  selector: 'truckload-add-carrier-association',
  templateUrl: './add-carrier-association.component.html',
  styleUrls: ['./add-carrier-association.component.scss'],
})
export class AddCarrierAssociationComponent implements OnInit {
  @Input()
  carrierCode: string;
  @Output()
  selectedAppUserChange: EventEmitter<AppUser> = new EventEmitter();

  appUser: AppUser;

  constructor() {}

  ngOnInit() {}

  changeAppUser($event) {
    this.appUser = $event.appUser;
  }

  addAssociation() {
    this.selectedAppUserChange.emit(this.appUser);
  }
}
