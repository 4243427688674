import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';
import { CarrierOfferDC } from './CarrierOfferDC';
import { AuthService } from '../../authentication/_services/auth.service';
import { UserDetails } from './UserDetails';
import { CarrierSummaryDC } from '../../carrier/dto/CarrierSummaryDC';
import { SearchService } from '../../carrier-search/services/search.service';
import { ActiveShipmentDC } from '../../carrier/dto/ActiveShipmentDC';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../configuration/Configuration';
import { GetShipmentByPrimaryReferenceDC } from './GetShipmentByPrimaryReferenceDC';
import { CurrencyService } from '../../Services/currency.service';
import { TenderValidationResponse } from './TenderValidationResponse';

@Injectable()
export class CarrierOfferService {
  config: Configuration;
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private searchServie: SearchService,
    private store: Store<AppState>,
    private currencyService: CurrencyService,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  createOffer(
    shipmentId: number,
    userDetails: UserDetails,
    offer: CarrierOfferDC,
  ): Observable<any> {
    const headers = new HttpHeaders({
      UserID: userDetails.userID.toString(),
      UserName: userDetails.userName,
      EnterpriseID: userDetails.enterpriseID.toString(),
    });

    return this.http.post<any>(
      `${this.config.shipmentServiceUrl}shipment/${shipmentId}/rate`,
      offer,
      { headers: headers },
    );
  }

  findShipment(
    bgNumber: string,
    userDetails: UserDetails,
  ): Observable<GetShipmentByPrimaryReferenceDC> {
    const headers = new HttpHeaders({
      UserID: userDetails.userID.toString(),
      UserName: userDetails.userName,
      EnterpriseID: userDetails.enterpriseID.toString(),
    });

    return this.http.get<GetShipmentByPrimaryReferenceDC>(
      `${this.config.shipmentServiceUrl}shipment/getByPrimaryReference/${bgNumber}`,
      {
        headers: headers,
      },
    );
  }

  @Cacheable()
  getUserDetails(): Observable<UserDetails> {
    const headers = new HttpHeaders({
      UserName: this.authService.loggedAppUser.appUserName,
    });
    return this.http.get<UserDetails>(
      `${this.config.shipmentServiceUrl}user/current`,
      { headers: headers },
    );
  }

  @Cacheable()
  getCarrierSummary(carrierCode: string): Observable<CarrierSummaryDC> {
    return this.http.get<CarrierSummaryDC>(
      `${this.config.carrierServiceUrl}${carrierCode}/summary`,
    );
  }

  @Cacheable()
  getActiveShipment(shipmentId: number): Observable<ActiveShipmentDC> {
    return this.searchServie.getActiveShipment(shipmentId);
  }

  getCustomerCurrencyCode(
    shipmentId: number,
    userDetails: UserDetails,
  ): Promise<string> {
    const headers = new HttpHeaders({
      UserID: userDetails.userID.toString(),
      UserName: userDetails.userName,
      EnterpriseID: userDetails.enterpriseID.toString(),
    });
    // Hack to circumvent expectations of v1 url
    const shipmentV2Url = this.config.shipmentServiceUrl.replace('v1', 'v2');
    return this.http
      .get<any>(`${shipmentV2Url}${shipmentId}/ShipmentRate/target`, {
        headers: headers,
      })
      .toPromise()
      .then((x) => {
        return x.targetRate.currencyCode;
      });
  }

  public async getRate(
    effectiveDate: string,
    currencyCode: string,
  ): Promise<number> {
    if (currencyCode === 'USD') {
      return Promise.resolve(1);
    }
    const countryCode = this.toCountryCode(currencyCode);
    const results = await (
      await this.currencyService.getCurrency(effectiveDate)
    ).toPromise();
    const selectedRate = results.find((x) => x.country === countryCode);
    return selectedRate.value || 1;
  }

  validateShipmentCarrierTender(
    shipmentId: number,
    carrierCode: string,
  ): Promise<TenderValidationResponse> {
    return this.http
      .get<TenderValidationResponse>(
        `${this.config.carrierServiceUrl}Tender/validate-tender`,
        {
          params: {
            shipmentId: shipmentId.toString(),
            carrierCode: carrierCode,
          },
        },
      )
      .toPromise();
  }

  private toCountryCode(currencyCode: string): string {
    const currencyCodeMap = {
      USD: 'USA',
      CAD: 'CAN',
    };
    return currencyCodeMap[currencyCode.toUpperCase()];
  }
}
