import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { FUMCarrierDocument } from '../models/FUMCarrierDocument';
import { CarrierDocument } from '../models/CarrierDocument';

@Injectable()
export class FUMCarrierDocumentService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  add(
    carrierCode: string,
    carrierDocument: CarrierDocument,
    file: File,
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('carrierCode', carrierCode);

    for (const key in carrierDocument) {
      if (carrierDocument.hasOwnProperty(key)) {
        formData.append(key, (carrierDocument as any)[key]);
      }
    }

    return this.http.post<any>(
      `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierCode}`,
      formData,
    );
  }

  update(
    carrierCode: string,
    carrierDocument: CarrierDocument,
  ): Observable<FUMCarrierDocument> {
    return this.http.put<FUMCarrierDocument>(
      `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierCode}`,
      carrierDocument,
    );
  }

  delete(carrierCode: string, carrierDocumentID: number): Observable<any> {
    return this.http.delete(
      `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierCode}/${carrierDocumentID}`,
    );
  }

  download(carrierCode: string, carrierDocumentID: number): Observable<Blob> {
    const downloadUrl = `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierCode}/${carrierDocumentID}/image`;
    const file = this.http.get<Blob>(downloadUrl, {
      responseType: 'blob' as 'json',
    });
    return file;
  }
}
