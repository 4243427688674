import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierAddress } from '../../models/CarrierAddress';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class AddressService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getAddresses(carrierCode: string): Observable<CarrierAddress[]> {
    return this.http.get<CarrierAddress[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/address`
    );
  }

  getAddressTypes(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.config.carrierServiceUrl}addresstypes`
    );
  }

  create(
    address: CarrierAddress,
    carrierCode: string
  ): Observable<CarrierAddress> {
    return this.http.post<CarrierAddress>(
      `${this.config.carrierServiceUrl}${carrierCode}/address`,
      address
    );
  }

  update(
    address: CarrierAddress,
    carrierCode: string
  ): Observable<CarrierAddress> {
    return this.http.put<CarrierAddress>(
      `${this.config.carrierServiceUrl}${carrierCode}/address`,
      address
    );
  }

  delete(carrierCode: string, carrierAddressID: number): Observable<any> {
    return this.http.delete(
      `${this.config.carrierServiceUrl}${carrierCode}/address/${carrierAddressID}`
    );
  }
}
