export class FUMUpdateCarrier {
  constructor(
    public carrierCode: string,
    public carrierName: string,
    public carrierDbaName: string,
    public customerName: string,
    public status: string,
    public isPay: boolean,
  ) {}
}
