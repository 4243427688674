import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import { CarrierLaneSearchParameters } from '../../models/CarrierLaneSearchParameters';
import * as SearchActions from '../../actions/search.actions';

@Component({
  selector: 'truckload-carrier-lane-search',
  templateUrl: './carrier-lane-search.component.html',
  styleUrls: ['./carrier-lane-search.component.scss'],
})
export class CarrierLaneSearchComponent implements OnInit {
  @ViewChild('lanesSearchForm', { static: true }) form: NgForm;
  laneSearchParams: CarrierLaneSearchParameters;
  laneSearchForm: FormGroup;
  truckTypes: string[];

  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Search.carrierLaneSearchParams)
      .subscribe((x) => {
        this.laneSearchParams = x;
        this.laneSearchForm = new FormGroup({
          origin: new FormControl(
            this.laneSearchParams.origin,
            Validators.required,
          ),
          destination: new FormControl(
            this.laneSearchParams.destination,
            Validators.required,
          ),
          odh: new FormControl(this.laneSearchParams.odh, Validators.required),
          ddh: new FormControl(this.laneSearchParams.ddh, Validators.required),
          truckTypes: new FormControl(this.laneSearchParams.truckTypes),
        });
      });

    this.store
      .select((x) => x.Search.truckTypes)
      .subscribe((x) => {
        this.truckTypes = x;
      });
  }

  ngOnInit() {}

  submitSearch() {
    var newParams = Object.assign(
      {},
      this.laneSearchForm.value,
    ) as CarrierLaneSearchParameters;
    this.store.dispatch(
      new SearchActions.CarrierLaneGridPaging({ skip: 0, take: 25 }),
    );
    this.store.dispatch(new SearchActions.SearchPreferredLane(newParams));
  }
}
