import { OnInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import { CarrierNameSearchParamters } from '../../models/CarrierNameSearchParamters';
import * as SearchActions from '../../actions/search.actions';

@Component({
  selector: 'name-search',
  templateUrl: './name-search.component.html',
  styleUrls: ['./name-search.component.scss'],
})
export class NameSearchComponent implements OnInit {
  carrierNameSearchParamers: CarrierNameSearchParamters;

  constructor(private store: Store<AppState>) {
    store
      .select((x) => x.Search.carrierNameSearchParams)
      .subscribe((x) => {
        this.carrierNameSearchParamers = x;
      });
  }

  ngOnInit(): void {}

  submitNameSearch() {
    this.store.dispatch(
      new SearchActions.SearchName(this.carrierNameSearchParamers),
    );
    this.store.dispatch(
      new SearchActions.CarrierGridPaging({ skip: 0, take: 25 }),
    );
  }
}
