import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { RefTruckType } from '../../../shared/references/RefTruckType';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { PortfolioService } from '../../services/portfolio.service';
import { PortfolioSearchResult } from '../../models/PortfolioSearchResult';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CarrierSearchResult } from '../../../carrier-search/models/CarrierSearchResults';

@Component({
  selector: 'truckload-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, OnDestroy {
  carrierIdentification: string;
  carrierName: string;
  truckTypes: RefTruckType[];

  truckTypeList: RefTruckType[];
  errors: any[] = [];
  showLoading: boolean;

  searchResults: PortfolioSearchResult[];
  repChangedBehavior: Subscription;

  constructor(
    private authService: AuthService,
    private portfolioService: PortfolioService
  ) {}

  ngOnInit() {
    this.repChangedBehavior =
      this.portfolioService.portfolioRepChangedBehavior.subscribe((x) => {
        this.loadPortfolio();
      });
  }

  ngOnDestroy(): void {
    this.repChangedBehavior.unsubscribe();
  }

  canViewPostMatch(dataItem: PortfolioSearchResult): boolean {
    return (
      this.authService.can(Permissions.ViewPostMatch) && dataItem.carrierID > 0
    );
  }

  loadPortfolio() {
    this.showLoading = true;
    this.portfolioService.getPortFolio().subscribe(
      (response) => {
        this.searchResults = response as PortfolioSearchResult[];
      },
      (err) => {
        if (err.status !== 401) {
          this.errors.push('something went wrong!');
        }
      },
      () => {
        this.showLoading = false;
      }
    );
  }

  getRouterLinkForDetails(dataItem: PortfolioSearchResult): any[] {
    return dataItem.fumCarrierID
      ? ['./fum-details/', dataItem.carrierCode]
      : ['./details/', dataItem.carrierCode];
  }
}
