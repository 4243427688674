import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Submitable } from '../../models/Submitable';
import { AppState } from '../../../appstate.model';
import { CarrierPreferredLane } from '../../models/CarrierPreferredLane';
import { CarrierDefaults } from '../../models/CarrierDefaults';
import { RefAPILocation } from '../../../shared/Models/RefAPILocation';
import {
  EditPreferredLane,
  CreatePreferredLane,
} from '../../actions/preferred-lanes.actions';
import { RefTruckType } from '../../../shared/references/RefTruckType';
// import { SetRefreshCarrierCrmLeadStatusOn } from '../../actions/carrier-details.actions';

@Component({
  selector: 'truckload-preferred-lane-edit',
  templateUrl: './preferred-lane-edit.component.html',
  styleUrls: ['./preferred-lane-edit.component.scss'],
})
export class PreferredLaneEditComponent extends Submitable implements OnInit {
  preferredLane: CarrierPreferredLane;
  editForm: FormGroup;
  serverError: any;
  allMethods: Array<string> = [];

  @ViewChild('origin', { static: true }) public origin: any;
  @ViewChild('destination', { static: true }) public destination: any;

  carrierCode: string;
  carrierDefaults: CarrierDefaults;
  refTruckTypes: RefTruckType[];
  truckTypes: string[];
  directionTypes: string[];
  constructor(
    dialog: DialogRef,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
  ) {
    super(dialog);

    store
      .select((x) => x.PreferredLanes)
      .subscribe((x) => {
        this.carrierDefaults =
          x.carrierDefaults == null ? new CarrierDefaults() : x.carrierDefaults;
        this.refTruckTypes = x.refTruckTypes;
        this.truckTypes = x.refTruckTypes.map((x) => x.code);
        this.directionTypes = x.refDirections.map((x) => x.direction);
      });

    store
      .select((x) => x.PreferredLanes.updateLoading)
      .subscribe((x) => {
        if (this.editForm && !x) {
          this.cancel();
        }
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
    });

    if (this.preferredLane != null) {
      let origin = {
        refAPILocationID: this.preferredLane.origin.refAPILocationID,
        city: this.preferredLane.origin.city,
        cityState: `${this.preferredLane.origin.city}, ${this.preferredLane.origin.stateProvince}`,
        stateProvince: this.preferredLane.origin.stateProvince,
        countryCode: this.preferredLane.origin.countryCode,
        latitude: this.preferredLane.origin.latitude,
        longitude: this.preferredLane.origin.longitude,
      } as RefAPILocation;

      let dest = {
        refAPILocationID: this.preferredLane.destination.refAPILocationID,
        city: this.preferredLane.destination.city,
        cityState: `${this.preferredLane.destination.city}, ${this.preferredLane.destination.stateProvince}`,
        stateProvince: this.preferredLane.destination.stateProvince,
        countryCode: this.preferredLane.destination.countryCode,
        latitude: this.preferredLane.destination.latitude,
        longitude: this.preferredLane.destination.longitude,
      } as RefAPILocation;

      this.editForm = new FormGroup({
        carrierPreferredLaneID: new FormControl(
          this.preferredLane.carrierPreferredLaneID,
          Validators.required,
        ),
        origin: new FormControl(origin, Validators.required),
        originDeadheadMiles: new FormControl(
          this.preferredLane.originDeadheadMiles,
          Validators.required,
        ),
        destination: new FormControl(dest, Validators.required),
        destinationDeadheadMiles: new FormControl(
          this.preferredLane.destinationDeadheadMiles,
          Validators.required,
        ),
        truckTypeCode: new FormControl(
          this.preferredLane.truckTypeCode,
          Validators.required,
        ),
        truckLengthUOM: new FormControl(
          this.preferredLane.truckLengthUOM,
          Validators.required,
        ),
        truckLengthValue: new FormControl(
          this.preferredLane.truckLengthValue,
          Validators.required,
        ),
        frequency: new FormControl(
          this.preferredLane.frequency,
          Validators.required,
        ),
        direction: new FormControl(
          this.preferredLane.direction,
          Validators.required,
        ),
      });
    } else {
      this.editForm = new FormGroup({
        carrierPreferredLaneID: new FormControl(0),
        origin: new FormControl(
          this.carrierDefaults.origin,
          Validators.required,
        ),
        originDeadheadMiles: new FormControl(
          this.carrierDefaults.originDeadheadMiles,
          Validators.required,
        ),
        destination: new FormControl(
          this.carrierDefaults.destination,
          Validators.required,
        ),
        destinationDeadheadMiles: new FormControl(
          this.carrierDefaults.destinationDeadheadMiles,
          Validators.required,
        ),
        truckTypeCode: new FormControl(
          this.carrierDefaults.truckTypeCode,
          Validators.required,
        ),
        truckLengthUOM: new FormControl('ft', Validators.required),
        truckLengthValue: new FormControl(
          this.carrierDefaults.truckLengthValue,
          Validators.required,
        ),
        frequency: new FormControl(null, Validators.required),
        direction: new FormControl(null, Validators.required),
      });
    }

    setTimeout(() => {
      this.origin.autocomplete.searchbar.input.nativeElement.focus();
    }, 0);
  }

  public save(): void {
    this.disableAndSubmitButton();
    const lane = this.editForm.value as CarrierPreferredLane;
    lane.truckType = this.refTruckTypes.find(
      (x) => x.code == lane.truckTypeCode,
    ).truckType;
    if (this.preferredLane) {
      this.store.dispatch(new EditPreferredLane(this.carrierCode, lane));
    } else {
      this.store.dispatch(new CreatePreferredLane(this.carrierCode, lane));
      //this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
    }
  }

  public cancel(): void {
    this.dialog.close({ text: 'Cancel' });
  }
}
