import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RefAPILocation } from '../shared/Models/RefAPILocation';
import { Configuration } from '../shared/configuration/Configuration';
import { AppState } from '../appstate.model';

@Injectable()
export class AddressLookupService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  search(filter: string): Observable<Array<RefAPILocation>> {
    return this.http
      .get<
        Array<RefAPILocation>
      >(`${this.config.carrierServiceUrl}location/search?filter=${filter}`)
      .pipe(
        map((j: RefAPILocation[]) =>
          j.map((x) => {
            x.cityState = x.city + ', ' + x.stateProvince;
            return x;
          }),
        ),
      );
  }
}
