import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarrierSearchComponent } from './components/carrier-search-page/carrier-search.component';

const routes: Routes = [
  {
    path: '',
    component: CarrierSearchComponent,
    data: {
      breadcrumb: 'Carrier Search',
    },
  },
  {
    path: 'carrier',
    loadChildren: () =>
      import('../carrier/carrier.module').then((m) => m.CarrierModule),
    data: {
      breadcrumb: 'Carrier',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SearchRoutingModule {}
