import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DialogService } from '@progress/kendo-angular-dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FUMCarrierService } from '../../../../../src/app/Services/fum-carrier.service';
import { BehaviorSubject } from 'rxjs';
import { FUMCarrier } from '../../models/FUMCarrier';
import { AuthService } from '../../../../../src/app/authentication/_services/auth.service';
import { Permissions } from '../../../../../src/app/shared/Enums/Permissions';
import { ModalComponent } from '../../../../../src/app/modal/modal.component';
import { FUMCarrierStatusComponent } from './fum-carrier-status/fum-carrier-status.component';
import { FUMCarrierAddressesComponent } from './fum-carrier-addresses/fum-carrier-addresses.component';
import { CarrierAddress } from '../../models/CarrierAddress';
import { FUMCarrierAddressService } from '../../services/fum-carrier-address.service';
import { CarrierAssociation } from '../../models/CarrierAssociation';
import { FUMCarrierAppUserRelationComponent } from './fum-carrier-appuser-relation/fum-carrier-appuser-relation.component';
import { FUMCarrierAppUserRelation } from '../../models/FUMCarrierAppUserRelation';
import { FUMCarrierAppUserRelationService } from '../../services/fum-carrier-association.service';
import { FUMCarrierCertificationComponent } from './fum-carrier-certifications/fum-carrier-certifications.component';
import { FUMCarrierNoteComponent } from './fum-carrier-notes/fum-carrier-notes.component';
import { FUMCarrierContactsComponent } from './fum-carrier-contacts/fum-carrier-contacts.component';
import { CarrierNote } from '../../models/CarrierNote';
import { FUMCarrierNoteService } from '../../services/fum-carrier-note.service';
import { CarrierContact } from '../../models/CarrierContact';
import { FUMCarrierContactService } from '../../services/fum-carrier-contact.service';
import { CarrierContactVerificationStatus } from '../../models/CarrierContact';
import { CarrierDocument } from '../../models/CarrierDocument';
import { FUMCarrierDocumentComponent } from './fum-carrier-documents/fum-carrier-documents.component';
import { FUMCarrierDocumentService } from '../../services/fum-carrier-document.service';
import { CarrierInsurance } from '../../models/CarrierInsurance';
import { FUMCarrierInsuranceService } from '../../services/fum-carrier-insurance.service';
import { FUMCarrierInsuranceComponent } from './fum-carrier-insurance/fum-carrier-insurance.component';
import { LoadHistoryDC } from '../../models/LoadHistoryDC';
import { CarrierLoadHistoryService } from '../../carrier-file/carrier-load-history/carrier-load-history.service';
import { CarrierOfferComponent } from '../../../shared/carrier-offer/carrier-offer.component';
import { CarrierOfferDC } from '../../models/CarrierOfferDC';
import { CarrierOfferService } from '../../../shared/carrier-offer/carrier-offer.service';

@Component({
  selector: 'fum-truckload-carrier-file',
  templateUrl: './fum-carrier-file.component.html',
  styleUrls: ['./fum-carrier-file.component.scss']
})
export class FUMCarrierFileComponent implements OnInit, AfterViewInit {
  @ViewChild('detailMain', { static: false }) detailMain: ElementRef;
  @ViewChild('detailBody', { static: false }) detailBody: ElementRef;
  @ViewChild('statusModal', { static: false }) statusModal: ModalComponent;
  @ViewChild('editStatusForm', { static: false })
  editStatusForm: FUMCarrierStatusComponent;
  @ViewChild('addressModal', { static: false }) addressModal: ModalComponent;
  @ViewChild('addressForm', { static: false })
  addressForm: FUMCarrierAddressesComponent;
  @ViewChild('addressDeleteConfirmationModal', { static: false })
  addressDeleteConfirmationModal: ModalComponent;
  @ViewChild('associationModal', { static: false })
  associationModal: ModalComponent;
  @ViewChild('associationForm', { static: false })
  associationForm: FUMCarrierAppUserRelationComponent;
  @ViewChild('associationDeleteConfirmationModal', { static: false })
  associationDeleteConfirmationModal: ModalComponent;
  @ViewChild('certificationModal', { static: false })
  certificationModal: ModalComponent;
  @ViewChild('certificationForm', { static: false })
  certificationForm: FUMCarrierCertificationComponent;
  @ViewChild('notesModal', { static: false }) notesModal: ModalComponent;
  @ViewChild('notesForm', { static: false }) notesForm: FUMCarrierNoteComponent;
  @ViewChild('noteDeleteConfirmationModal', { static: false })
  noteDeleteConfirmationModal: ModalComponent;
  @ViewChild('contactsModal', { static: false }) contactsModal: ModalComponent;
  @ViewChild('contactsForm', { static: false })
  contactsForm: FUMCarrierContactsComponent;
  @ViewChild('contactDeleteConfirmationModal', { static: false })
  contactDeleteConfirmationModal: ModalComponent;
  @ViewChild('documentsModal', { static: false })
  documentsModal: ModalComponent;
  @ViewChild('documentsForm', { static: false })
  documentsForm: FUMCarrierDocumentComponent;
  @ViewChild('documentDeleteConfirmationModal', { static: false })
  documentDeleteConfirmationModal: ModalComponent;
  @ViewChild('contactDeleteDocumentModal', { static: false })
  documentDeleteModal: ModalComponent;
  @ViewChild('insuranceModal', { static: false })
  insuranceModal: ModalComponent;
  @ViewChild('insuranceForm', { static: false })
  insuranceForm: FUMCarrierInsuranceComponent;
  @ViewChild('insuranceDeleteConfirmationModal', { static: false })
  insuranceDeleteConfirmationModal: ModalComponent;
  @ViewChild('downloadZipLink', { static: true }) downloadZipLink: ElementRef;

  carrierCode: string;
  outBGCode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  fumCarrierFile: FUMCarrier;
  addressForEdit: CarrierAddress;
  addressForDelete: CarrierAddress;
  associationForDelete: FUMCarrierAppUserRelation;
  noteForDelete: CarrierNote;
  contactForEdit: CarrierContact;
  contactForDelete: CarrierContact;
  documentForEdit: CarrierDocument;
  documentForDelete: CarrierDocument;
  carrierAssociations: CarrierAssociation[];
  primaryContact: CarrierContact;
  insuranceGridData: CarrierInsurance[];
  insuranceForEdit: CarrierInsurance;
  insuranceForDelete: CarrierInsurance;
  showInactiveInsurance = false;
  FUMCarrierViews = FUMCarrierViews;
  loadHistoryData: LoadHistoryDC[];

  currentView: FUMCarrierViews = FUMCarrierViews.AssociationIdentities;

  errorMessages = [];
  isErrorWindowOpen = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public fumCarrierService: FUMCarrierService,
    public authService: AuthService,
    public loadHistorySvc: CarrierLoadHistoryService,
    public fumCarrierAddressService: FUMCarrierAddressService,
    private fumCarrierAppUserRelationService: FUMCarrierAppUserRelationService,
    public fumCarrierNoteService: FUMCarrierNoteService,
    public fumCarrierContactService: FUMCarrierContactService,
    public fumCarrierDocumentService: FUMCarrierDocumentService,
    public fumCarrierInsuranceService: FUMCarrierInsuranceService,
    private carrierOfferService: CarrierOfferService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private location: Location
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
      if (!this.carrierCode) {
        this.router.navigate(['/carrier/search']);
      }
    });

    this.loadFUMCarrierFileDetails();

    this.loadHistorySvc.getLoadHistory(this.carrierCode).subscribe((data) => {
      this.loadHistoryData = data;
    });
  }

  ngAfterViewInit(): void {
    this.setDetailMainHeight();
  }

  setDetailMainHeight(): void {
    if (this.fumCarrierFile) {
      const childHeight = this.detailBody.nativeElement.offsetHeight;

      this.detailMain.nativeElement.style.height = `${childHeight * 1.35}px`;
    } else {
      setTimeout(() => {
        this.setDetailMainHeight();
      }, 50);
    }
  }

  loadFUMCarrierFileDetails(): void {
    this.fumCarrierService.getAggregate(this.carrierCode).subscribe(
      (data) => {
        this.fumCarrierFile = data;

        this.primaryContact = this.fumCarrierFile.carrierContacts.find(
          (contact) => contact.isPrimary
        );

        this.fumCarrierFile.fumCarrierAppUserRelations.sort((a, b) => {
          if (a.firstName === b.firstName) {
            return a.lastName < b.lastName ? -1 : 1;
          }
          return a.firstName < b.firstName ? -1 : 1;
        });

        this.fumCarrierFile.fumCarrierAppUserRelations.sort((a, b) => {
          return a.isRep === b.isRep ? 0 : a.isRep ? -1 : 1;
        });

        this.fumCarrierFile.carrierContacts.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });

        this.fumCarrierFile.carrierContacts.sort((a, b) => {
          return a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1;
        });

        this.fumCarrierFile.carrierContacts.map((contact) => {
          contact.verificationStatus =
            contact.verificationStatus ||
            CarrierContactVerificationStatus.Unverified;
          contact.carrierCode = this.fumCarrierFile.carrierCode;
        });

        this.fumCarrierFile.carrierDocuments.sort((a, b) => {
          return (
            new Date(b.dateUpdated).getTime() -
            new Date(a.dateUpdated).getTime()
          );
        });

        this.fumCarrierFile.carrierDocuments.map((document) => {
          document.dateUpdatedFormatted = this.formatDate(document.dateUpdated);
        });

        this.fumCarrierFile.carrierInsurances.map((insurance) => {
          // must dates convert to JS date objects, for kendo tools to bind correctly
          insurance.expirationDate = insurance.expirationDate
            ? new Date(insurance.expirationDate)
            : null;
          insurance.effectiveDate = insurance.effectiveDate
            ? new Date(insurance.effectiveDate)
            : null;
          insurance.cancelDate = insurance.cancelDate
            ? new Date(insurance.cancelDate)
            : null;
          insurance.expirationDateFormatted = this.formatDate(
            insurance.expirationDate
          );
          insurance.effectiveDateFormatted = this.formatDate(
            insurance.effectiveDate
          );
          insurance.cancelDateFormatted = this.formatDate(insurance.cancelDate);
        });

        this.fumCarrierFile.carrierNotes.sort((a, b) => {
          return (
            new Date(b.dateCreated).getTime() -
            new Date(a.dateCreated).getTime()
          );
        });

        this.fumCarrierFile.carrierNotes.map((note) => {
          note.dateCreatedFormatted = this.formatDate(note.dateCreated);
        });

        this.applyInsuranceToggle();

        setTimeout(() => {
          let jumpLink =
            this.router.url.split('%23').length > 1
              ? this.router.url.split('%23')[1]
              : this.router.url.split('#')[1];
          this.navJumpLink(jumpLink);
        }, 50);
      },
      (err) => {
        this.toastr.error(
          `<div class="mt-2" style="width: 100%;">Error: "${
            err.error
              ? !err.error.error
                ? err.error
                : err.error.error
              : err.message
          }"<div>`,
          'FUM Carrier Could Not Be Loaded!'
        );
        this.router.navigate(['/search']);
      }
    );
  }

  private formatDate(date: Date): string | null {
    if (!date) return null;

    const dateResult = new Date(date);
    const pad = (num) => num.toString().padStart(2, '0');

    return `${pad(dateResult.getMonth() + 1)}/${pad(dateResult.getDate())}/${dateResult.getFullYear()} ${pad(dateResult.getHours())}:${pad(dateResult.getMinutes())}`;
  }

  openStatusModal(): void {
    const optionsModal = {
      width: 500,
      title: 'Edit FUM Carrier Status Form',
      resizable: false
    };

    this.editStatusForm.resetForm();
    this.statusModal.showConfirmation(optionsModal);
  }

  openAddAddressModal(): void {
    const optionsModal = {
      width: 1200,
      title: 'Add FUM Carrier Address',
      resizable: false
    };
    this.addressForEdit = null;

    setTimeout(() => {
      this.addressForm.resetForm();
      this.addressModal.showConfirmation(optionsModal);
    }, 5);
  }

  openEditAddressModal(address: CarrierAddress): void {
    const optionsModal = {
      width: 1200,
      title: 'Edit FUM Carrier Address',
      resizable: false
    };
    this.addressForEdit = address;

    setTimeout(() => {
      this.addressForm.resetForm();
      this.addressModal.showConfirmation(optionsModal);
    }, 5);
  }

  openAddAssociationModal(): void {
    const optionsModal = {
      width: 800,
      title: 'Add FUM Carrier/AppUser Association',
      resizable: false
    };
    this.associationForm.resetForm();

    this.associationModal.showConfirmation(optionsModal);
  }

  openEditCertificationModal(): void {
    const optionsModal = {
      width: 550,
      title: 'Add/Remove FUM Carrier Certification(s)',
      resizable: false
    };
    this.certificationForm.resetForm();

    this.certificationModal.showConfirmation(optionsModal);
  }

  openAddNotesModal(): void {
    const optionsModal = {
      width: 450,
      title: 'Add FUM Carrier Note',
      resizable: false
    };

    this.notesForm.resetForm();
    this.notesModal.showConfirmation(optionsModal);
  }

  openAddContactModal(): void {
    const optionsModal = {
      width: 800,
      title: 'Add FUM Carrier Contact',
      resizable: false
    };
    this.contactForEdit = null;

    setTimeout(() => {
      this.contactsForm.resetForm();
      this.contactsModal.showConfirmation(optionsModal);
    }, 5);
  }

  openEditContactModal(contact: CarrierContact): void {
    const optionsModal = {
      width: 800,
      title: 'Edit FUM Carrier Contact',
      resizable: false
    };
    this.contactForEdit = contact;

    setTimeout(() => {
      this.contactsForm.resetForm();
      this.contactsModal.showConfirmation(optionsModal);
    }, 5);
  }

  openAddDocumentModal(): void {
    const optionsModal = {
      width: 650,
      title: 'Add FUM Carrier Document',
      resizable: false
    };
    this.documentForEdit = null;

    setTimeout(() => {
      this.documentsForm.resetForm();
      this.documentsModal.showConfirmation(optionsModal);
    }, 5);
  }

  openEditDocumentModal(document: CarrierDocument): void {
    const optionsModal = {
      width: 650,
      title: 'Edit FUM Carrier Document',
      resizable: false
    };
    this.documentForEdit = document;

    setTimeout(() => {
      this.documentsForm.resetForm();
      this.documentsModal.showConfirmation(optionsModal);
    }, 5);
  }

  openAddInsuranceModal(): void {
    const optionsModal = {
      width: 850,
      title: 'Add FUM Carrier Insurance',
      resizable: false
    };
    this.insuranceForEdit = null;

    setTimeout(() => {
      this.insuranceForm.resetForm();
      this.insuranceModal.showConfirmation(optionsModal);
    }, 5);
  }

  openEditInsuranceModal(insurance: CarrierInsurance): void {
    const optionsModal = {
      width: 800,
      title: 'Edit FUM Carrier Insurance',
      resizable: false
    };
    this.insuranceForEdit = insurance;

    setTimeout(() => {
      this.insuranceForm.resetForm();
      this.insuranceModal.showConfirmation(optionsModal);
    }, 5);
  }

  closeStatusModal(): void {
    this.statusModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeAddressModal(): void {
    this.addressModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeAssociationModal(): void {
    this.associationModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeCertificationModal(): void {
    this.certificationModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeContactsModal(): void {
    this.contactsModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeDocumentsModal(): void {
    this.documentsModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeInsuranceModal(): void {
    this.insuranceModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  closeNotesModal(): void {
    this.notesModal.closeModal();
    this.loadFUMCarrierFileDetails();
  }

  confirmAddressDelete(address: CarrierAddress): void {
    this.addressForDelete = address;
    const optionsModal = {
      width: 500,
      title: 'Delete Address',
      resizable: false
    };

    this.addressDeleteConfirmationModal.showConfirmation(optionsModal);
  }

  confirmAssociationDelete(association: FUMCarrierAppUserRelation): void {
    this.associationForDelete = association;
    const optionsModal = {
      width: 500,
      title: 'Delete Carrier/AppUser Association',
      resizable: false
    };

    this.associationDeleteConfirmationModal.showConfirmation(optionsModal);
  }

  confirmContactDelete(contact: CarrierContact): void {
    this.contactForDelete = contact;
    const optionsModal = {
      width: 500,
      title: 'Delete Carrier Contact',
      resizable: false
    };

    this.contactDeleteConfirmationModal.showConfirmation(optionsModal);
  }

  confirmDocumentDelete(document: CarrierDocument): void {
    this.documentForDelete = document;
    const optionsModal = {
      width: 500,
      title: 'Delete Carrier Document',
      resizable: false
    };

    this.documentDeleteConfirmationModal.showConfirmation(optionsModal);
  }

  confirmNoteDelete(note: CarrierNote): void {
    this.noteForDelete = note;
    const optionsModal = {
      width: 500,
      title: 'Delete Carrier Note',
      resizable: false
    };

    this.noteDeleteConfirmationModal.showConfirmation(optionsModal);
  }

  confirmInsuranceDelete(insurance: CarrierInsurance): void {
    this.insuranceForDelete = insurance;
    const optionsModal = {
      width: 500,
      title: 'Delete Carrier Insurance',
      resizable: false
    };

    this.insuranceDeleteConfirmationModal.showConfirmation(optionsModal);
  }

  downloadDocument(carrierDocument: CarrierDocument) {
    this.fumCarrierDocumentService
      .download(
        this.fumCarrierFile.carrierCode,
        carrierDocument.carrierDocumentID
      )
      .subscribe((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = carrierDocument.contentUrl;
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  deleteAddress(): void {
    this.addressDeleteConfirmationModal.closeModal();
    this.fumCarrierAddressService
      .delete(this.addressForDelete.carrierAddressID)
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Address Deleted Successfully!',
            'Success'
          );
          this.loadFUMCarrierFileDetails();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            `FUM Carrier Address Could Not Be Deleted!`
          );
        }
      );

    this.addressForDelete = null;
  }

  deleteAssociation(): void {
    this.associationDeleteConfirmationModal.closeModal();
    this.fumCarrierAppUserRelationService
      .deleteCarrierAssociation(
        this.associationForDelete.carrierAppUserRelationID
      )
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier/AppUser Association Deleted Successfully!',
            'Success'
          );
          this.loadFUMCarrierFileDetails();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "
          ${
            err.error
              ? !err.error.error
                ? err.error
                : err.error.error
              : err.message
          }"<div>`,
            `FUM Carrier/AppUser Association Could Not Be Deleted!`
          );
        }
      );

    this.addressForDelete = null;
  }

  deleteContact(): void {
    this.contactDeleteConfirmationModal.closeModal();
    this.fumCarrierContactService
      .delete(
        this.fumCarrierFile.carrierCode,
        this.contactForDelete.carrierContactID
      )
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Contact Deleted Successfully!',
            'Success'
          );
          this.loadFUMCarrierFileDetails();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            `FUM Carrier Contact Could Not Be Deleted!`
          );
        }
      );
  }

  deleteDocument(): void {
    this.documentDeleteConfirmationModal.closeModal();
    this.fumCarrierDocumentService
      .delete(
        this.fumCarrierFile.carrierCode,
        this.documentForDelete.carrierDocumentID
      )
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Contact Deleted Successfully!',
            'Success'
          );
          this.loadFUMCarrierFileDetails();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            `FUM Carrier Contact Could Not Be Deleted!`
          );
        }
      );
  }

  deleteNote(): void {
    this.noteDeleteConfirmationModal.closeModal();
    this.fumCarrierNoteService
      .delete(this.noteForDelete.carrierNoteID)
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Note Deleted Successfully!',
            'Success'
          );
          this.loadFUMCarrierFileDetails();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            `FUM Carrier Note Could Not Be Deleted!`
          );
        }
      );

    this.noteForDelete = null;
  }

  deleteInsurance(): void {
    this.insuranceDeleteConfirmationModal.closeModal();
    this.fumCarrierInsuranceService
      .delete(this.insuranceForDelete.carrierInsuranceID)
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Note Deleted Successfully!',
            'Success'
          );
          this.loadFUMCarrierFileDetails();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            `FUM Carrier Note Could Not Be Deleted!`
          );
        }
      );

    this.noteForDelete = null;
  }

  navJumpLink(jumpLink): void {
    if (jumpLink) {
      let baseUrl =
        this.router.url.split('%23').length > 1
          ? this.router.url.split('%23')[0]
          : this.router.url.split('#')[0];
      let currentUrl = `${baseUrl}#${jumpLink}`;
      this.currentView = jumpLink;

      this.location.go(currentUrl);
      this.router.navigateByUrl(currentUrl);

      document.getElementById(jumpLink).scrollIntoView();
    }
  }

  applyInsuranceToggle() {
    this.insuranceGridData = this.fumCarrierFile.carrierInsurances.filter(
      (x) => this.showInactiveInsurance || x.status.toUpperCase() === 'VALID'
    );
  }

  setIncludeInactiveRecords() {
    this.showInactiveInsurance = !this.showInactiveInsurance;
    this.applyInsuranceToggle();
  }

  showOfferDialog(): void {
    const offerRef = this.dialogService.open({
      content: CarrierOfferComponent,
      width: 800
    });

    offerRef.content.instance.carrierSummary = this.fumCarrierFile;

    offerRef.result.toPromise().then((dialogResult: any) => {
      if (!dialogResult.result) {
        return;
      }

      const effectiveDate = moment(new Date()).format('L');
      const currencyCode = dialogResult.result.currencyCode;

      Promise.all([
        this.carrierOfferService.getRate(effectiveDate, currencyCode),
        this.carrierOfferService.getUserDetails().toPromise()
      ])
        .then((results) => {
          const rate = results[0];
          const userDetails = results[1];
          const convertedBuyRate = dialogResult.result.buyRate / rate;
          let offer = {
            carrierCode: this.fumCarrierFile.carrierCode,
            carrierName: this.fumCarrierFile.carrierName,
            carrierDbaName: this.fumCarrierFile.carrierName,
            dotNumber: this.fumCarrierFile.dotNumber,
            mcNumber: this.fumCarrierFile.mcNumber,
            scac: this.fumCarrierFile.scac,
            currencyCode: currencyCode,
            carrierChargeInUSD: convertedBuyRate,
            carrierCharge: dialogResult.result.buyRate,
            customerCharge: 0,
            notes: dialogResult.result.quoteNote
          } as CarrierOfferDC;

          return this.carrierOfferService
            .createOffer(dialogResult.result.shipmentID, userDetails, offer)
            .toPromise();
        })
        .then(
          () => {
            this.toastr.success(
              'FUM Carrier Offer/Quote Created Successfully!',
              'Success'
            );
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${
                err.error
                  ? !err.error.error
                    ? err.error
                    : err.error.error
                  : err.message
              }"<div>`,
              'FUM Carrier Offer/Quote Could Not Be Updated!'
            );
          }
        );
    });
  }

  get fumCarrierFileArray() {
    return [this.fumCarrierFile];
  }

  get canCreateOfferFromDetails(): boolean {
    return this.authService.can(Permissions.CreateOfferFromDetails);
  }

  get canEditBGStatus(): boolean {
    return this.authService.can(Permissions.EditBGStatus);
  }

  get canEditAddresses(): boolean {
    return this.authService.can(Permissions.EditAddress);
  }

  get canDeleteContacts(): boolean {
    return this.authService.can(Permissions.DeleteCarrierContact);
  }

  get canEditContacts(): boolean {
    return this.authService.can(Permissions.EditContacts);
  }

  get canEditDocuments(): boolean {
    return this.authService.can(Permissions.EditCarrierDocment);
  }

  get canDeleteDocments(): boolean {
    return this.authService.can(Permissions.DeleteCarrierDocment);
  }

  get canEditInsurance(): boolean {
    return this.authService.can(Permissions.EditInsurance);
  }

  get canDeleteInsurance(): boolean {
    return this.authService.can(Permissions.DeleteInsurance);
  }
}

export enum FUMCarrierViews {
  Addresses = 'addresses',
  CertificationsNotes = 'certifications-notes',
  Contacts = 'contacts',
  AssociationIdentities = 'association-identities',
  SettingsDocuments = 'settings-documents',
  Insurance = 'insurance',
  LoadHistory = 'load-history'
}
