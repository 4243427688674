import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierAddress } from '../models/CarrierAddress';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class FUMCarrierAddressService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  create(carrierAddress: CarrierAddress): Observable<CarrierAddress> {
    return this.http.post<CarrierAddress>(
      `${this.config.carrierServiceUrl}fum/carrieraddress`,
      carrierAddress,
    );
  }

  update(carrierAddress: CarrierAddress): Observable<CarrierAddress> {
    return this.http.put<CarrierAddress>(
      `${this.config.carrierServiceUrl}fum/carrieraddress`,
      carrierAddress,
    );
  }

  delete(carrierAddressID: number): Observable<any> {
    return this.http.delete<any>(
      `${this.config.carrierServiceUrl}fum/carrieraddress/${carrierAddressID}`,
    );
  }
}
