import { CarrierNote } from './CarrierNote';
import { CarrierCertification } from './CarrierCertification';
import { FUMCarrierAppUserRelation } from './FUMCarrierAppUserRelation';
import { CarrierContact } from './CarrierContact';
import { FUMCarrierIdentity } from './FUMCarrierIdentity';
import { CarrierDocument } from './CarrierDocument';
import { CarrierAddress } from './CarrierAddress';
import { CarrierInsurance } from './CarrierInsurance';

export class FUMCarrier {
  carrierID: number;
  carrierName: string;
  carrierDbaName: string;
  carrierCode: string;
  customerName: string;
  displayName: string;
  isPay: boolean;
  status: string;
  mainAddress: CarrierAddress;
  stateID: string;
  stateIDType: string;
  dotNumber: string;
  mcNumber: string;
  mxNumber: string;
  ffNumber: string;
  scac: string;
  carrierAddresses: CarrierAddress[];
  fumCarrierCertifications: CarrierCertification[];
  carrierContacts: CarrierContact[];
  carrierDocuments: CarrierDocument[];
  carrierIdentities: FUMCarrierIdentity[];
  carrierInsurances: CarrierInsurance[];
  fumCarrierAppUserRelations: FUMCarrierAppUserRelation[];
  carrierNotes: CarrierNote[];
}
