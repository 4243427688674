import { forkJoin as observableForkJoin } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import { LoadCarrierDetails } from '../../actions/carrier-details.actions';
import {
  InternalInsuranceOrigination,
  InsuranceService,
} from '../../services/insurance.service';
import { CarrierInsurance } from '../../models/CarrierInsurance';
import { RefService } from '../../../shared/references/ref.service';
import { CommonService } from '../../../Services/common.service';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { GridComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { CarrierErrors } from '../CarrierErrors';
import { RefInsurance } from '../../../shared/references/RefInsurance';

@Component({
  selector: 'truckload-carrier-insurance',
  templateUrl: './carrier-insurance.component.html',
  styleUrls: ['./carrier-insurance.component.scss'],
  encapsulation: ViewEncapsulation.None, // Disabled for styling issues
})
export class CarrierInsuranceComponent implements OnInit {
  @Input()
  carrierCode: string;
  @ViewChild('carrierInsuranceGrid', { static: true })
  carrierInsuranceGrid: GridComponent;

  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  insuranceTypeList: RefInsurance[];
  insuranceGridData: CarrierInsurance[];
  allInsuranceData: CarrierInsurance[];

  errorMessages = [];
  itemToRemove: any;
  insuranceLoaded = false;
  includeInactiveInsurance = false;

  constructor(
    public insuranceService: InsuranceService,
    public refService: RefService,
    public commonService: CommonService,
    private authService: AuthService,
    private store: Store<AppState>,
  ) {
    this.createNewInsurance = this.createNewInsurance.bind(this);
    this.removeInsuranceConfirmation =
      this.removeInsuranceConfirmation.bind(this);
  }

  ngOnInit() {
    this.loadInsurance();
  }

  loadInsurance() {
    observableForkJoin([
      // these need to be in the same order!
      this.insuranceService.getInsurances(this.carrierCode), // 0
      this.refService.getInsuranceTypes(), // 1
    ]).subscribe((response) => {
      this.refreshInsuranceData(response[0]);
      this.insuranceTypeList = response[1];
      this.insuranceLoaded = true;
    });
  }

  getCanEditInsurance(): boolean {
    return this.authService.can(Permissions.EditInsurance);
  }

  getCanDeleteInsurance(): boolean {
    return this.authService.can(Permissions.DeleteInsurance);
  }

  createNewInsurance(): CarrierInsurance {
    return {
      carrierInsuranceID: 0,
      carrierID: 0,
      fumCarrierID: null,
      status: '',
      refInsurance: null,
      underwriter: '',
      cargoLimitAmount: 0,
      effectiveDate: null,
      expirationDate: null,
      cancelDate: null,
      effectiveDateFormatted: null,
      expirationDateFormatted: null,
      cancelDateFormatted: null,
      policyNumber: '',
      deductibleAmount: 0,
      originationSource: InternalInsuranceOrigination,
    };
  }

  saveInsurance({ sender, rowIndex, dataItem, isNew }): void {
    this.insuranceService
      .insertUpdateCarrierInsurance(this.carrierCode, dataItem)
      .subscribe(
        (data) => {
          this.refreshInsuranceData(data);
          // Keep truckload-carrier-details in sync
          this.store.dispatch(new LoadCarrierDetails(this.carrierCode));
        },
        (err) => {
          if (err.status === 400) {
            for (const fieldName in err.error) {
              if (err.error.hasOwnProperty(fieldName)) {
                this.errorMessages.push(`${err.error[fieldName]}`);
              }
            }
            sender.editRow(rowIndex);
          }
        },
      );
  }

  removeInsuranceConfirmation(dataItem): boolean {
    this.itemToRemove = dataItem;
    return false;
  }

  removeInsurance(shouldRemove: boolean): void {
    if (!shouldRemove) {
      this.itemToRemove = null;
      return;
    }
    this.insuranceService
      .deleteCarrierInsurance(
        this.carrierCode,
        this.itemToRemove.carrierInsuranceID,
      )
      .subscribe(
        (data) => {
          this.refreshInsuranceData(data);
          // Keep truckload-carrier-details in sync
          this.store.dispatch(new LoadCarrierDetails(this.carrierCode));
        },
        (err) => {
          const error = {} as CarrierErrors;
          error.redirectToCarrierSearch = false;
          error.errors = [`${err.status}: ${err.statusText}`];
          this.openErrorWindow.emit(error);
          this.loadInsurance();
        },
        () => {
          this.itemToRemove = null;
        },
      );
  }

  insuranceTypes(grid: any[]): any {
    const it = [];
    this.insuranceGridData.forEach((element) => {
      if (
        !it.some((item) => {
          return item.refInsuranceID === element.refInsurance.refInsuranceID;
        })
      ) {
        it.push(element.refInsurance);
      }
    });
    return it;
  }

  isEditableCell(
    dataItem: CarrierInsurance,
    column?: ColumnComponent | undefined,
  ): boolean {
    if (column && column.field === 'status') return false;
    // Third party data should never be editable directly by employees.
    if (dataItem && dataItem.originationSource != InternalInsuranceOrigination)
      return false;

    return true;
  }

  refreshInsuranceData(data: CarrierInsurance[]) {
    this.allInsuranceData = data;
    this.applyInsuranceToggle();
  }

  applyInsuranceToggle() {
    this.insuranceGridData = this.allInsuranceData.filter(
      (x) =>
        this.includeInactiveInsurance || x.status.toUpperCase() === 'VALID',
    );
  }
}
