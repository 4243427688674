import { debounceTime } from 'rxjs/operators';
import {
  Component,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewInit,
  forwardRef,
} from '@angular/core';

import { Observable } from 'rxjs';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { RefAPILocation } from '../Models/RefAPILocation';
import { AddressLookupService } from '../../Services/address-lookup.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'truckload-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressLookupComponent),
      multi: true,
    },
  ],
})
export class AddressLookupComponent
  implements AfterViewInit, ControlValueAccessor
{
  @ViewChild('autocomplete', { static: true })
  public autocomplete: ComboBoxComponent;

  @Input()
  address: RefAPILocation;
  @Input()
  isRequired: boolean;

  @Output()
  addressChange: EventEmitter<RefAPILocation> = new EventEmitter();

  matches: RefAPILocation[] = [];
  onChange: any;

  constructor(private addressLookupService: AddressLookupService) {}

  ngAfterViewInit() {
    // default to address if exists from input
    if (this.address) {
      this.matches.push(this.address);
    }

    this.autocomplete.filterChange
      .asObservable()
      .pipe(debounceTime(300))
      .subscribe((filter) => this.handleFilter(filter));
  }
  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.address = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // TODO: ??
  }
  setDisabledState?(isDisabled: boolean): void {
    // TODO: ??
  }
  public handleFilter(value: string) {
    if (value.length < 3) {
      this.autocomplete.toggle(false);
      return;
    }
    this.addressSearch(value).subscribe((result) => {
      this.matches = result;
    });
  }

  public selectionChange(value: RefAPILocation): void {
    this.address = value;
    this.addressChange.emit(this.address);
    if (this.onChange) this.onChange(value);
  }

  addressSearch(filter: string): Observable<Array<RefAPILocation>> {
    return this.addressLookupService.search(filter);
  }
}
