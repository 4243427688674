import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierContact } from '../models/CarrierContact';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { CarrierContactVerificationStatus } from '../models/CarrierContact';

export type VerificationQueryParams = {
  code: string; // verification code
  resp: string; // response
  ts: string; // timestamp
  sig: string; // signature
};

export const InternalContactOriginationSource = 'Internal' as const;

@Injectable()
export class ContactService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getContacts(carrierCode: string): Observable<CarrierContact[]> {
    return this.http.get<CarrierContact[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/contact`,
    );
  }

  getContactById(
    carrierCode: string,
    carrierContactId: number,
  ): Observable<CarrierContact> {
    return this.http.get<CarrierContact>(
      `${this.config.carrierServiceUrl}contact/${carrierContactId}`,
    );
  }

  deleteCarrierContact(
    carrierCode: string,
    carrierContactID: number,
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.config.carrierServiceUrl}${carrierCode}/contact/${carrierContactID}`,
    );
  }

  create(
    carrierCode: string,
    contact: CarrierContact,
  ): Observable<CarrierContact[]> {
    return this.http.post<CarrierContact[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/contact`,
      contact,
    );
  }

  update(
    carrierCode: string,
    contact: CarrierContact,
  ): Observable<CarrierContact[]> {
    return this.http.put<CarrierContact[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/contact`,
      contact,
    );
  }

  sendVerification(
    carrierCode: string,
    carrierContactID: number,
  ): Observable<any> {
    return this.http.post<CarrierContact>(
      `${this.config.carrierServiceUrl}${carrierCode}/contact/${carrierContactID}/verify`,
      null,
    );
  }

  resetVerification(
    carrierCode: string,
    carrierContactID: number,
  ): Observable<any> {
    return this.http.put<CarrierContact>(
      `${this.config.carrierServiceUrl}${carrierCode}/contact/${carrierContactID}/verify/reset`,
      null,
    );
  }

  // Public Endpoint - no auth
  confirmVerification(params: VerificationQueryParams): Observable<void> {
    return this.http.get<void>(
      `${this.config.carrierServiceUrl}contact/verify`,
      {
        params,
      },
    );
  }

  overrideVerification(
    carrierContactID: number,
    verificationStatus: CarrierContactVerificationStatus,
  ) {
    return this.http.put<void>(
      `${this.config.carrierServiceUrl}contact/${carrierContactID}/override-verification-status`,
      { verificationStatus },
    );
  }
}
