import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RefService } from '../../shared/references/ref.service';
import { StateList } from '../../shared/Enums/StateList';
import { PageService } from '../../Services/page.service';
import { CarrierService } from '../../Services/carrier.service';
import { NewCarrier } from '../models/NewCarrier';
import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';
import { identitiesValidator } from './carrier-id-validation.directive';
import { Geolocation } from '../../shared/Models/Geolocation';

@Component({
  selector: 'truckload-new-carrier-file',
  templateUrl: './new-carrier-file.component.html',
  styleUrls: ['./new-carrier-file.component.scss']
})
export class NewCarrierFileComponent implements OnInit {
  @Input() opened: boolean = false;
  googleSearchLocation: Geolocation = {} as Geolocation;
  manualLocationChecked = false;
  stateProvinceList = Object.keys(StateList);
  carrierForm: FormGroup;
  identitiesForm: FormGroup;
  serverError: string;
  constructor(
    public carrierService: CarrierService,
    public refService: RefService,
    private pageService: PageService,
    private router: Router,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.pageService.setPageTitle('New Manual Carrier');

    this.resetForm();
  }

  public resetForm() {
    this.identitiesForm = new FormGroup(
      {
        dotNumber: new FormControl(null),
        mcNumber: new FormControl(null),
        stateID: new FormControl(null),
        stateIDType: new FormControl(null)
      },
      { validators: [identitiesValidator] }
    );
    this.carrierForm = new FormGroup({
      legalName: new FormControl(null, Validators.required),
      dbaName: new FormControl(null),
      addressLine1: new FormControl(null, Validators.required),
      addressLine2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      identities: this.identitiesForm
    });
  }

  get legalName() {
    return this.carrierForm.get('legalName');
  }

  get addressLine1() {
    return this.carrierForm.get('addressLine1');
  }

  get city() {
    return this.carrierForm.get('city');
  }

  get state() {
    return this.carrierForm.get('state');
  }

  get postalCode() {
    return this.carrierForm.get('postalCode');
  }

  get dotNumber() {
    return this.identitiesForm.get('dotNumber');
  }

  get mcNumber() {
    return this.identitiesForm.get('mcNumber');
  }

  get stateID() {
    return this.identitiesForm.get('stateID');
  }

  get stateIDType() {
    return this.identitiesForm.get('stateIDType');
  }

  /* set form values from google location search */
  public locationChanged(location: Geolocation) {
    if (location) {
      this.carrierForm.patchValue({
        city: location.city,
        state: location.stateProvince,
        postalCode: location.postalCode
      });
    }

    this.city.markAsTouched();
    this.state.markAsTouched();
    this.postalCode.markAsTouched();
  }

  get canEditManualLocation(): boolean {
    return this.authService.can(Permissions.EditManualLocation);
  }

  /* clears location input controls when clicking manual location entry checkbox */
  public useManualLocationChanged() {
    this.carrierForm.patchValue({
      city: null,
      state: null,
      postalCode: null
    });

    this.city.markAsPristine();
    this.state.markAsPristine();
    this.postalCode.markAsPristine();
  }

  submit() {
    const newCarrier = Object.assign({}, this.carrierForm.value) as NewCarrier;
    const identities = Object.assign(
      {},
      this.identitiesForm.value
    ) as NewCarrier;

    newCarrier.dotNumber = identities.dotNumber;
    newCarrier.mcNumber = identities.mcNumber;
    newCarrier.stateID = identities.stateID;
    newCarrier.stateIDType = identities.stateIDType;

    this.carrierService.createNewManual(newCarrier).subscribe(
      (carrier) => {
        this.router.navigateByUrl(`/search/details/${carrier.carrierCode}`);
      },
      (err) => {
        this.serverError = '';

        if (err.error) {
          // Basic error messages:
          if (typeof err.error === 'string') {
            this.serverError = err.error;
          }
          // Complex validation messages:
          else if (typeof err.error === 'object') {
            let errorMessages: string[] = [];

            Object.keys(err.error).forEach(
              (x) => (errorMessages = errorMessages.concat(err.error[x]))
            );

            this.serverError = errorMessages.join('\r\n');
          }
        }

        // Unrecognized error type
        if (this.serverError == '') {
          this.serverError = 'Unexpected server error occurred';
        }
      }
    );
  }
}
