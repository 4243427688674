import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarrierFileDetails } from './models/CarrierFileDetails';
import { Store } from '@ngrx/store';
import { LoadTruckPost } from './actions/truck-post.actions';
import { AppState } from '../../appstate.model';
@Component({
  selector: 'truckload-post-match-details',
  templateUrl: './post-match-details.component.html',
  styleUrls: ['./post-match-details.component.scss'],
})
export class PostMatchDetailsComponent implements OnInit {
  carrierCode: string;
  cd: CarrierFileDetails;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
  ) {
    store
      .select((x) => x.TruckPost.details)
      .subscribe((x) => {
        this.cd = x;
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
      this.store.dispatch(new LoadTruckPost(this.carrierCode));
    });
  }
}
