import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '../../../Services/common.service';
import { forkJoin } from 'rxjs';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { CarrierErrors } from '../CarrierErrors';
import { InventoryService } from './inventory.service';
import { InventoryDC } from './InventoryDC';
// import { SetRefreshCarrierCrmLeadStatusOn } from '../../actions/carrier-details.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Component({
  selector: 'truckload-carrier-inventory',
  templateUrl: './carrier-inventory.component.html',
  styleUrls: ['./carrier-inventory.component.scss'],
})
export class CarrierInventoryComponent implements OnInit {
  @Input() carrierCode: string;

  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  loading = false;
  editMode: boolean;

  carrierInventory: InventoryDC;
  originalCarrierInventory: InventoryDC;

  constructor(
    public authService: AuthService,
    public commonService: CommonService,
    public inventoryService: InventoryService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.loading = true;

    forkJoin([
      // these need to be in the same order!
      this.inventoryService.getInventory(this.carrierCode), // 0
    ]).subscribe((response) => {
      this.carrierInventory = response[0];
      this.loading = false;
    });
  }

  getTrailerCount(): number {
    if (!this.carrierInventory || !this.carrierInventory.inventory) {
      return 0;
    }
    return this.carrierInventory.inventory
      .map((x) => (x.inventory ? x.inventory : 0))
      .reduce((t, i) => {
        return t + i;
      });
  }

  get canEdit(): boolean {
    return this.authService.can(Permissions.EditInventory);
  }

  edit(): void {
    this.originalCarrierInventory = JSON.parse(
      JSON.stringify(this.carrierInventory),
    );
    this.toggleEditMode();
  }

  save(): void {
    this.loading = true;
    if (this.canEdit) {
      this.inventoryService
        .insertUpdateCarrierInventory(this.carrierCode, this.carrierInventory)
        .subscribe(
          (data) => {
            this.carrierInventory = data;
            this.loading = false;
            //this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOn());
          },
          (err) => {
            if (err.status === 404) {
              const error = {} as CarrierErrors;
              error.errors = [];
              error.errors.push(err.error);
              error.redirectToCarrierSearch = true;
              this.openErrorWindow.emit(error);
            }
          },
        );
    }
    this.toggleEditMode();
  }

  cancel(): void {
    this.carrierInventory = JSON.parse(
      JSON.stringify(this.originalCarrierInventory),
    );
    this.toggleEditMode();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }
}
