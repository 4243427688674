import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { FUMCarrierDocument } from '../models/FUMCarrierDocument';
import { CarrierDocument } from '../models/CarrierDocument';
import { FUMCarrier } from '../models/FUMCarrier';

@Injectable()
export class FUMCarrierDocumentService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  add(
    fumCarrier: FUMCarrier,
    carrierDocument: CarrierDocument,
    file: File
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('carrierCode', fumCarrier.carrierCode);
    formData.append('fumCarrierID', fumCarrier.carrierID.toString());

    for (const key in carrierDocument) {
      if (carrierDocument.hasOwnProperty(key)) {
        formData.append(key, (carrierDocument as any)[key]);
      }
    }

    return this.http.post<any>(
      `${this.config.carrierServiceUrl}FUM/CarrierDocument`,
      formData
    );
  }

  update(carrierDocument: CarrierDocument): Observable<FUMCarrierDocument> {
    return this.http.put<FUMCarrierDocument>(
      `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierDocument.carrierDocumentID}`,
      carrierDocument
    );
  }

  delete(carrierDocumentID: number): Observable<any> {
    return this.http.delete(
      `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierDocumentID}`
    );
  }

  download(carrierDocumentID: number): Observable<Blob> {
    const downloadUrl = `${this.config.carrierServiceUrl}FUM/CarrierDocument/${carrierDocumentID}/download`;
    return this.http.get<Blob>(downloadUrl, {
      responseType: 'blob' as 'json'
    });
  }
}
