import { Component } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CarrierSearchResult } from '../../models/CarrierSearchResults';
import { AppState } from '../../../appstate.model';
import { Store } from '@ngrx/store';
import * as SearchActions from '../../actions/search.actions';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { PortfolioSearchResult } from '../../../portfolio/models/PortfolioSearchResult';
import { Permissions } from '../../../shared/Enums/Permissions';

@Component({
  selector: 'truckload-carrier-grid',
  templateUrl: './carrier-grid.component.html',
  styleUrls: ['./carrier-grid.component.scss']
})
export class CarrierGridComponent {
  results: CarrierSearchResult[];
  sort: SortDescriptor[] = [];
  private canViewPostMatchValue: boolean;
  gridState: PageChangeEvent = { skip: 0, take: 25 };

  constructor(private store: Store<AppState>) {
    this.store
      .select((x) => x.Search.carrierSearchResults)
      .subscribe((x) => {
        this.results = x;
      });

    this.store
      .select((x) => x.Search.canViewPostMatch)
      .subscribe((x) => {
        this.canViewPostMatchValue = x;
      });

    this.store
      .select((x) => x.Search.carrierGridSort)
      .subscribe((x) => {
        this.sort = x;
      });

    this.store
      .select((x) => x.Search.carrierGridPaging)
      .subscribe((x) => {
        if (x) {
          this.gridState = x;
        }
      });
  }

  public pageChange(event: PageChangeEvent): void {
    this.store.dispatch(new SearchActions.CarrierGridPaging(event));
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.store.dispatch(new SearchActions.CarrierGridSort(sort));
  }

  canViewPostMatch(dataItem: PortfolioSearchResult): boolean {
    return this.canViewPostMatchValue && dataItem.carrierID > 0;
  }

  getRouterLinkForDetails(dataItem: CarrierSearchResult): any[] {
    return dataItem.fumCarrierID
      ? ['./fum-details/', dataItem.carrierCode]
      : ['./details/', dataItem.carrierCode];
  }
}
