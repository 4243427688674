import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierCertification } from '../models/CarrierCertification';
import { FUMSaveCarrierCertifications } from '../models/FUMSaveCarrierCertifications';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class FumCarrierCertificationService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getCertifications(carrierCode: string): Observable<CarrierCertification[]> {
    return this.http.get<CarrierCertification[]>(
      `${this.config.carrierServiceUrl}fum/carrierCertification?carrierCode=${carrierCode}`,
    );
  }

  saveCarrierCertifications(
    carrierCode: string,
    refCertificationIDs: number[],
  ): Observable<number> {
    let fumSaveCarrierCertifications: FUMSaveCarrierCertifications = {
      carrierCode: carrierCode,
      refCertificationIDs: refCertificationIDs,
    };

    console.log(JSON.stringify(fumSaveCarrierCertifications));

    return this.http.post<number>(
      `${this.config.carrierServiceUrl}fum/carrierCertification/Save`,
      fumSaveCarrierCertifications,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }
}
