import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../../shared/configuration/Configuration';

@Injectable({
  providedIn: 'root',
})
export class CarrierSyncService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  syncHighwayCarrier(dot: string, mc: string, status: string, email: string) {
    let req = {
      dot: dot,
      mcnumber: mc,
      status: status,
      email: email,
    };
    return this.http.post(
      `${this.config.carrierServiceUrl}carrierSync/single`,
      req,
      { responseType: 'text' },
    );
  }
}
