import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  SimpleChanges,
  Output,
} from '@angular/core';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { CarrierNote } from '../../../../carrier/models/CarrierNote';
import { FUMCarrierNoteService } from '../../../../carrier/services/fum-carrier-note.service';

@Component({
  selector: 'fum-carrier-notes',
  templateUrl: './fum-carrier-notes.component.html',
})
export class FUMCarrierNoteComponent implements OnInit, OnChanges {
  @Input() fumCarrierFile: FUMCarrier;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  addForm: FormGroup;

  constructor(
    public fumCarrierNoteService: FUMCarrierNoteService,
    public authService: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.fumCarrierFile &&
      changes.fumCarrierFile.currentValue &&
      !changes.fumCarrierFile.previousValue
    ) {
      this.initForm();
    }
  }

  private initForm() {
    if (!this.addForm && this.fumCarrierFile) {
      this.addForm = new FormGroup({
        comment: new FormControl('', Validators.required),
      });
    }
  }

  cancel() {
    this.resetForm();

    this.close.emit();
  }

  save() {
    if (this.addForm.valid) {
      const formValue = this.addForm.value;

      const fumCarrierNote: CarrierNote = {
        carrierNoteID: 0,
        carrierID: this.fumCarrierFile.carrierID,
        comment: formValue.comment,
        userCreated: this.authService.authUser.profile.name,
        dateCreated: new Date(),
        dateCreatedFormatted: '',
      };

      this.fumCarrierNoteService.save(fumCarrierNote).subscribe(
        (data) => {
          this.toastr.success(
            'FUM Carrier Note Created Successfully!',
            'Success',
          );
          this.close.emit();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            'FUM Carrier Note Could Not Be Updated!',
          );
          this.close.emit();
        },
      );
    }
  }

  resetForm() {
    this.addForm.reset({
      comment: '',
    });
  }

  get comment() {
    return this.addForm.get('comment');
  }
}
