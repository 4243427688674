import { CarrierIdentityDC } from '../dto/CarrierIdentityDC';
import { CarrierIdentityState } from './carrier-identity-state.model';

export class CarrierIdentity {
  type: string;
  value: string;

  constructor(identity: CarrierIdentityDC) {
    this.type = identity.type;
    this.value = identity.value;
  }
}

export interface RefIdentity {
  type: string;
  group: string;
}

export class CarrierIdentities {
  public identifierGroup: CarrierIdentity[];
  public systemGroup: CarrierIdentity[];
  public financialGroup: CarrierIdentity[];
  public docketGroup: CarrierIdentity[];
  public stateGroup: CarrierIdentity[];
  public canadianGroup: CarrierIdentity[];

  public availableIdentifier: string[];
  public availableSystem: string[];
  public availableFinancial: string[];
  public availableDocket: string[];
  public availableState: string[];
  public availableCanadian: string[];
  public availableAll: string[];

  public constructor(
    identities: CarrierIdentityDC[],
    state: CarrierIdentityState,
  ) {
    // current identities used by carrier
    this.identifierGroup = this.createIdentifierListByGroup(
      identities,
      state.refIdentifier,
    );
    this.systemGroup = this.createIdentifierListByGroup(
      identities,
      state.refSystem,
    );
    this.financialGroup = this.createIdentifierListByGroup(
      identities,
      state.refFinancial,
    );
    this.docketGroup = this.createIdentifierListByGroup(
      identities,
      state.refDocket,
    );
    this.stateGroup = this.createIdentifierListByGroup(
      identities,
      state.refState,
    );
    this.canadianGroup = this.createIdentifierListByGroup(
      identities,
      state.refCanadian,
    );

    // build available identities grouped by type
    this.availableIdentifier = this.createAvailableIdentities(
      identities,
      state.refIdentifier,
    );
    this.availableSystem = this.createAvailableIdentities(
      identities,
      state.refSystem,
    );
    this.availableFinancial = this.createAvailableIdentities(
      identities,
      state.refFinancial,
    );
    this.availableDocket = this.createAvailableIdentities(
      identities,
      state.refDocket,
    );
    this.availableState = this.createAvailableIdentities(
      identities,
      state.refState,
    );
    this.availableCanadian = this.createAvailableIdentities(
      identities,
      state.refCanadian,
    );

    // build a list of all identities that have not yet been used for the add identity drop down
    this.availableAll = state.refSystem
      .concat(state.refIdentifier, state.refDocket, state.refFinancial)
      .filter((x) => identities.findIndex((i) => i.type == x.type) == -1)
      .map((x) => x.type);
  }

  private createIdentifierListByGroup(
    idents: CarrierIdentityDC[],
    refs: RefIdentity[],
  ): CarrierIdentity[] {
    return idents
      .filter((x) => this.isIdTypeInGroup(x.type, refs))
      .map((x) => {
        return {
          type: x.type,
          value: x.value,
        } as CarrierIdentity;
      });
  }

  private isIdTypeInGroup(type: string, refs: RefIdentity[]): boolean {
    return refs.findIndex((x) => x.type == type) >= 0;
  }

  private createAvailableIdentities(
    identities: CarrierIdentityDC[],
    refs: RefIdentity[],
  ): string[] {
    return refs
      .filter((x) => identities.findIndex((i) => i.type == x.type) == -1)
      .map((x) => {
        return x.type;
      });
  }
}
