import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierInsurance } from '../models/CarrierInsurance';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

export const InternalInsuranceOrigination = 'Internal' as const;

@Injectable()
export class InsuranceService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getInsurances(carrierCode: string): Observable<CarrierInsurance[]> {
    return this.http
      .get<
        CarrierInsurance[]
      >(`${this.config.carrierServiceUrl}${carrierCode}/insurance`)
      .pipe(map((j: CarrierInsurance[]) => this.doMapping(j)));
  }

  insertUpdateCarrierInsurance(
    carrierCode: string,
    carrierInsurance: CarrierInsurance,
  ): Observable<CarrierInsurance[]> {
    return this.http
      .post<
        CarrierInsurance[]
      >(`${this.config.carrierServiceUrl}${carrierCode}/insurance`, carrierInsurance)
      .pipe(map((j: CarrierInsurance[]) => this.doMapping(j)));
  }

  deleteCarrierInsurance(
    carrierCode: string,
    carrierInsuranceID: number,
  ): Observable<CarrierInsurance[]> {
    return this.http
      .delete<
        CarrierInsurance[]
      >(`${this.config.carrierServiceUrl}${carrierCode}/insurance/${carrierInsuranceID}`)
      .pipe(map((j: CarrierInsurance[]) => this.doMapping(j)));
  }

  private doMapping(lst: CarrierInsurance[]): CarrierInsurance[] {
    return lst.map((x) => {
      x.effectiveDate = new Date(x.effectiveDate);
      x.cancelDate = x.cancelDate == null ? null : new Date(x.cancelDate);
      x.expirationDate = new Date(x.expirationDate);
      return x;
    });
  }
}
