import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierNote } from '../../models/CarrierNote';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class NoteService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  get(carrierCode: string): Observable<CarrierNote[]> {
    return this.http
      .get<CarrierNote[]>(`${this.config.carrierServiceUrl}${carrierCode}/note`)
      .pipe(
        map((j: CarrierNote[]) =>
          j.map((x) => {
            x.dateCreated = new Date(`${x.dateCreated}`);
            return x;
          }),
        ),
      );
  }

  save(
    carrierCode: string,
    carrierNote: CarrierNote,
  ): Observable<CarrierNote[]> {
    return this.http
      .post<
        CarrierNote[]
      >(`${this.config.carrierServiceUrl}${carrierCode}/note`, carrierNote)
      .pipe(
        map((j: CarrierNote[]) =>
          j.map((x) => {
            x.dateCreated = new Date(`${x.dateCreated}`);
            return x;
          }),
        ),
      );
  }

  delete(
    carrierCode: string,
    carrierNoteID: number,
  ): Observable<CarrierNote[]> {
    return this.http
      .delete<
        CarrierNote[]
      >(`${this.config.carrierServiceUrl}${carrierCode}/note/${carrierNoteID}`)
      .pipe(
        map((j: CarrierNote[]) =>
          j.map((x) => {
            x.dateCreated = new Date(`${x.dateCreated}`);
            return x;
          }),
        ),
      );
  }
}
