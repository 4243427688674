import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UploadEvent,
  SuccessEvent,
  ErrorEvent,
  FileRestrictions,
} from '@progress/kendo-angular-upload';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { DocumentService } from './document.service';
import { RefService } from '../../../shared/references/ref.service';
import { CarrierDocument } from '../../models/CarrierDocument';
import { CarrierDocumentUploadDetail } from './CarrierDocumentUploadDetail';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { CarrierErrors } from '../CarrierErrors';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { RefDocument } from '../../../shared/references/RefDocument';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Component({
  selector: 'truckload-carrier-documents',
  templateUrl: './carrier-documents.component.html',
  styleUrls: ['./carrier-documents.component.scss'],
})
export class CarrierDocumentsComponent implements OnInit {
  public loading: boolean;
  public docRestrictions: FileRestrictions = {
    maxFileSize: 50 * 1024 * 1024, // 50MB
  };
  @Input()
  carrierCode: string;
  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  documentTypeList: RefDocument[];
  documentsGrid: CarrierDocument[];
  uploadSaveUrl: string;
  uploadDetail: CarrierDocumentUploadDetail;
  @ViewChild('downloadZipLink', { static: true }) downloadZipLink: ElementRef;
  @ViewChild('carrierDocumentsGrid', { static: true })
  carrierDocumentsGrid: GridComponent;
  addingNewDocument = false;
  itemToRemove: CarrierDocument;
  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  config: Configuration;
  constructor(
    public documentService: DocumentService,
    public refService: RefService,
    private auth: AuthService,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
    this.removeDocConfirmation = this.removeDocConfirmation.bind(this);
  }

  ngOnInit() {
    this.refService.getCarrierDocumentTypes().subscribe((x) => {
      this.documentTypeList = x;
    });
    this.loadDocuments();
    this.uploadSaveUrl = `${this.config.carrierServiceUrl}${this.carrierCode}/document`;
    this.uploadDetail = {} as CarrierDocumentUploadDetail;
  }

  async download(carrierDocument: CarrierDocument) {
    this.documentService
      .downloadCarrierDocumentImage(
        this.carrierCode,
        carrierDocument.carrierDocumentID,
      )
      .subscribe((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = carrierDocument.contentUrl;
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  loadDocuments() {
    this.loading = true;
    this.documentService.getDocuments(this.carrierCode).subscribe(
      (x) => {
        this.documentsGrid = x.sort(this.uploadDocumentSort);
        this.loading = false;
      },
      (err) => {
        const carrierError: CarrierErrors = {
          errors: [`${err.status}: ${err.statusText}`],
          redirectToCarrierSearch: false,
        };
        this.openErrorWindow.emit(carrierError);
        this.loading = false;
      },
    );
  }

  get canAddEditCarrierDocuments(): boolean {
    return this.auth.can(Permissions.EditCarrierDocment);
  }

  get canDeleteCarrierDocments(): boolean {
    return this.auth.can(Permissions.DeleteCarrierDocment);
  }

  saveDocument(carrierDocument: CarrierDocument) {
    this.documentService
      .updateCarrierDocument(this.carrierCode, carrierDocument)
      .subscribe(
        () => { },
        (err) => {
          const error = {} as CarrierErrors;
          error.redirectToCarrierSearch = false;
          error.errors = [`${err.status}: ${err.statusText}`];
          this.openErrorWindow.emit(error);
          this.loadDocuments();
        },
      );
  }

  public removeDocConfirmation(dataItem): boolean {
    this.itemToRemove = dataItem;
    return false;
  }

  removeDocument(shouldRemove: boolean) {
    if (!shouldRemove) {
      this.itemToRemove = null;
      return;
    }
    this.documentService
      .deleteCarrierDocument(this.carrierCode, this.itemToRemove)
      .subscribe(
        () => {
          let index: number;
          const data = this.carrierDocumentsGrid.data as GridDataResult;
          for (let i = 0; i < data.total; i++) {
            if (
              data.data[i].carrierDocumentID ===
              this.itemToRemove.carrierDocumentID
            ) {
              index = i;
              break;
            }
          }
          if (index !== -1) {
            data.data.splice(index, 1);
          }
        },
        (err) => {
          const error = {} as CarrierErrors;
          error.redirectToCarrierSearch = false;
          error.errors = [`${err.status}: ${err.statusText}`];
          this.openErrorWindow.emit(error);
          this.loadDocuments();
        },
        () => {
          this.itemToRemove = null;
        },
      );
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = this.uploadDetail;
    e.headers.append('Authorization', this.auth.getAuthorizationHeaderValue());
  }

  uploadSuccess(e: SuccessEvent) {
    this.toggleAddDocumentForm();
    const uploadedDocuments = e.response.body as CarrierDocument[];
    this.documentsGrid = uploadedDocuments.sort(this.uploadDocumentSort);
  }

  uploadError(e: ErrorEvent) {
    const error = {} as CarrierErrors;
    error.redirectToCarrierSearch = false;
    error.errors = new Array<string>();
    error.errors.push('Upload failed.');
    if (e.response && e.response.status) {
      error.errors.push(`${e.response.status}: ${e.response.statusText}`);
    }
    this.openErrorWindow.emit(error);
  }

  uploadDocumentSort(a: CarrierDocument, b: CarrierDocument): number {
    if (a.isActive && !b.isActive) {
      return -1;
    }
    if (!a.isActive && b.isActive) {
      return 1;
    }

    return a.dateUpdated < b.dateUpdated
      ? 1
      : a.dateUpdated > b.dateUpdated
        ? -1
        : 0;
  }

  toggleAddDocumentForm(): void {
    this.addingNewDocument = !this.addingNewDocument;
  }
}
