import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierContact } from '../models/CarrierContact';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class FUMCarrierContactService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  create(
    carrierContact: CarrierContact,
  ): Observable<CarrierContact> {
    return this.http.post<CarrierContact>(
      `${this.config.carrierServiceUrl}FUM/CarrierContact/`,
      carrierContact
    );
  }

  update(
    carrierContact: CarrierContact,
  ): Observable<CarrierContact> {
    return this.http.put<CarrierContact>(
      `${this.config.carrierServiceUrl}FUM/CarrierContact/${carrierContact.carrierContactID}`,
      carrierContact
    );
  }

  delete(carrierContactID: number): Observable<any> {
    return this.http.delete(
      `${this.config.carrierServiceUrl}FUM/CarrierContact/${carrierContactID}`
    );
  }
}
