import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FollowToggleService } from './follow-toggle.service';
import { CarrierAssociation } from './CarrierAssociation';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'truckload-follow-carrier-toggle',
  templateUrl: './follow-carrier-toggle.component.html',
  styleUrls: ['./follow-carrier-toggle.component.scss'],
})
export class FollowCarrierToggleComponent implements OnInit {
  constructor(
    private toggleService: FollowToggleService,
    public activatedRoute: ActivatedRoute,
  ) {}

  @Output()
  followingToggleChanged: EventEmitter<any> = new EventEmitter();

  appUserID: number;
  carrierCode: string;
  isFollowing: boolean;

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
    });
    this.getCarrierToggleData();
  }

  getCarrierToggleData() {
    this.toggleService.getFollowing(this.carrierCode).subscribe((data) => {
      this.appUserID = data.appUserID;
      this.isFollowing = data.isFollowing;
    });
  }

  toggleFollowing() {
    if (this.isFollowing) {
      this.toggleService.follow(this.carrierCode).subscribe((data) => {
        this.followingToggleChanged.emit();
      });
    } else {
      this.toggleService.unfollow(this.carrierCode).subscribe((data) => {
        this.followingToggleChanged.emit();
      });
    }
  }
}
