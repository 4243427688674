import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  SimpleChanges,
  Output
} from '@angular/core';
import { BGStatusType } from '../../../../shared/Enums/BGStatusType';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { FUMCarrierService } from '../../../../Services/fum-carrier.service';
import { FUMUpdateCarrier } from '../../../../carrier/models/FUMUpdateCarrier';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fum-carrier-status',
  templateUrl: './fum-carrier-status.component.html',
  styleUrls: ['./fum-carrier-status.component.scss']
})
export class FUMCarrierStatusComponent implements OnInit, OnChanges {
  @Input() fumCarrierFile: FUMCarrier;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  bgStatusList = Object.values(BGStatusType);
  editForm: FormGroup;

  updatedStatusValue: string;

  constructor(
    public fumCarrierService: FUMCarrierService,
    public authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.fumCarrierFile &&
      changes.fumCarrierFile.currentValue &&
      !changes.fumCarrierFile.previousValue
    ) {
      this.initForm();
    }
  }

  private initForm() {
    if (!this.editForm && this.fumCarrierFile) {
      this.editForm = new FormGroup({
        status: new FormControl(
          this.fumCarrierFile.status,
          Validators.required
        ),
        isPay: new FormControl(this.fumCarrierFile.isPay)
      });
    }
  }

  cancel() {
    this.resetForm();

    this.close.emit();
  }

  save() {
    if (this.editForm.valid) {
      const formValue = this.editForm.value;

      const fumCarrierUpdate = new FUMUpdateCarrier(
        this.fumCarrierFile.carrierCode,
        this.fumCarrierFile.carrierName,
        this.fumCarrierFile.carrierDbaName,
        this.fumCarrierFile.customerName,
        formValue.status,
        formValue.isPay
      );

      this.fumCarrierService
        .update(fumCarrierUpdate, this.fumCarrierFile.carrierID)
        .subscribe(
          (data) => {
            this.toastr.success(
              'FUM Carrier Status Updated Successfully!',
              'Success'
            );
            this.fumCarrierFile.status = data.status;
            this.fumCarrierFile.isPay = data.isPay;
            this.close.emit();
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${
                err.error
                  ? !err.error.error
                    ? err.error
                    : err.error.error
                  : err.message
              }"<div>`,
              'FUM Carrier Status Could Not Be Updated!'
            );
            this.close.emit();
          }
        );
    }
  }

  resetForm() {
    this.editForm.reset({
      status: this.fumCarrierFile.status,
      isPay: this.fumCarrierFile.isPay
    });
  }

  get status() {
    return this.editForm.get('status').value;
  }

  get isPay() {
    return this.editForm.get('isPay').value;
  }
}
