import { CarrierFileDetails } from '../../models/CarrierFileDetails';
import { TruckPosting } from '../models/TruckPosting';
import { MatchSearchResult } from '../dto/TruckMatchDC';
import { CarrierNoCapacity } from './CarrierNoCapacity';
import { RefActivity } from '../../../shared/references/RefActivity';
import { RefDisposition } from '../../../shared/references/RefDisposition';
import { TruckPostingDefaults } from '../../carrier-file/carrier-defaults/TruckPostingDefaults';

export class TruckPostState {
  truckPostActivityId: number;
  noCapacityActivityId: number;
  noTruckActivityId: number;
  carrierCode: string;
  details: CarrierFileDetails;
  truckPosting: TruckPosting;
  postMatches: MatchSearchResult[];
  noCapacity: CarrierNoCapacity;
  activityTypes: RefActivity[];
  dispositionsTypes: RefDisposition[];
  postDefaults: TruckPostingDefaults;
  loading: boolean;
  loadingError: string;
  savingTruckPost: boolean;
  truckPostError: string;
  savingNoCapacity: boolean;
  noCapacityError: string;
  savingNoTruck: boolean;
  noTruckError: string;
  crmLeadStatus: string;
}
