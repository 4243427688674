import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierAssociation } from '../models/CarrierAssociation';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class FUMCarrierAppUserRelationService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getCarrierAssociations(
    carrierCode: string,
  ): Observable<CarrierAssociation[]> {
    return this.http.get<CarrierAssociation[]>(
      `${this.config.carrierServiceUrl}FUM/CarrierAppUserRelation?carrierCode=${carrierCode}`,
    );
  }

  insertUpdateCarrierAssociation(
    carrierCode: string,
    carrierAssociation: CarrierAssociation,
  ): Observable<CarrierAssociation[]> {
    return this.http.post<CarrierAssociation[]>(
      `${this.config.carrierServiceUrl}FUM/CarrierAppUserRelation`,
      carrierAssociation,
    );
  }

  deleteCarrierAssociation(carrierAppUserRelationID: number): Observable<any> {
    return this.http.delete<any>(
      `${this.config.carrierServiceUrl}FUM/CarrierAppUserRelation/${carrierAppUserRelationID}`,
    );
  }
}
