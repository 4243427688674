import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Configuration } from '../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../appstate.model';
import { map } from 'rxjs/operators';
import { FUMNewCarrier } from '../carrier/models/FUMNewCarrier';
import { FUMCarrier } from '../carrier/models/FUMCarrier';
import { FUMUpdateCarrier } from '../carrier/models/FUMUpdateCarrier';

@Injectable({
  providedIn: 'root',
})
export class FUMCarrierService {
  config: Configuration; // Replace with your API endpoint

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  searchFUMCarriers(
    term: string,
    selected: boolean = false,
    adding: boolean = false,
  ): Observable<string[]> {
    if (term.length === 0 || selected || adding) return of([]);

    return this.http
      .get<
        string[]
      >(`${this.config.carrierServiceUrl}FUM/carrier/searchCarrierNames?searchTerm=${term}`)
      .pipe(map((carriers) => carriers.sort()));
  }

  searchFUMCustomer(
    term: string,
    selected: boolean = false,
    adding: boolean = false,
  ): Observable<string[]> {
    if (term.length === 0 || selected || adding) return of([]);

    return this.http
      .get<
        string[]
      >(`${this.config.carrierServiceUrl}FUM/carrier/searchCustomerNames?searchTerm=${term}`)
      .pipe(map((carriers) => carriers.sort()));
  }

  createNewManualFUMCarrier(fumCarrier: FUMNewCarrier): Observable<any> {
    // MC is not a required field, but if you have it, make sure it is prepended with MC
    const mc = fumCarrier.mcNumber;
    fumCarrier.mcNumber =
      (mc && mc.toUpperCase().startsWith('MC')) || !mc ? mc : 'MC' + mc;

    return this.http.post<any>(
      `${this.config.carrierServiceUrl}FUM/carrier`,
      fumCarrier,
    );
  }

  getAggregate(carrierCode: string): Observable<FUMCarrier> {
    return this.http.get<FUMCarrier>(
      `${this.config.carrierServiceUrl}FUM/carrier/aggregate?carrierCode=${carrierCode}`,
    );
  }

  update(
    fumCarrierUpdate: FUMUpdateCarrier,
    carrierID: number,
  ): Observable<FUMCarrier> {
    return this.http.put<FUMCarrier>(
      `${this.config.carrierServiceUrl}FUM/carrier/${carrierID}`,
      fumCarrierUpdate,
    );
  }
}
