import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarrierFileComponent } from './carrier-file/carrier-file.component';
import { PostMatchDetailsComponent } from './post-match-details/post-match-details.component';
import { NewCarrierManagerGuard } from '../shared/guards/carrier/new-carrier-manager.guard';
import { CarrierFileManagerGuard } from '../shared/guards/carrier/carrier-file-manager.guard';
import { SyncCarrierFileComponent } from './sync-carrier-file/sync-carrier-file.component';
import { FUMCarrierFileComponent } from './fum-carrier/fum-carrier-file/fum-carrier-file.component';
import { FUMCarrierFileManagerGuard } from '../shared/guards/fum-carrier/fum-carrier-file-manager.guard';

const routes: Routes = [
  {
    path: 'details/:id',
    component: CarrierFileComponent,
    canActivate: [CarrierFileManagerGuard],
    data: {
      breadcrumb: 'Carrier Details',
    },
  },
  {
    path: 'fum-details/:id',
    component: FUMCarrierFileComponent,
    canActivate: [FUMCarrierFileManagerGuard],
    data: {
      breadcrumb: 'FUM Carrier Details',
    },
  },
  {
    path: 'postmatch/:id',
    component: PostMatchDetailsComponent,
    data: {
      breadcrumb: 'Post Match',
    },
  },
  {
    path: 'sync',
    component: SyncCarrierFileComponent,
    canActivate: [NewCarrierManagerGuard],
    data: {
      breadcrumb: 'Sync Carrier',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarrierRoutingModule { }
