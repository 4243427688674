import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  SimpleChanges,
  Output,
} from '@angular/core';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { CarrierInsurance } from '../../../models/CarrierInsurance';
import {
  InternalInsuranceOrigination,
} from '../../../services/insurance.service';
import { RefInsurance } from '../../../../shared/references/RefInsurance';
import { RefService } from '../../../../shared/references/ref.service';
import { FUMCarrierInsuranceService } from '../../../services/fum-carrier-insurance.service';

@Component({
  selector: 'fum-carrier-insurance',
  templateUrl: './fum-carrier-insurance.component.html',
})
export class FUMCarrierInsuranceComponent implements OnInit, OnChanges {
  @Input() fumCarrierFile: FUMCarrier;
  @Input() insurance: CarrierInsurance;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  activeInsurance: CarrierInsurance;
  insuranceForm: FormGroup;
  addInsurance: boolean = false;
  insuranceTypeList: RefInsurance[];

  constructor(
    public authService: AuthService,
    public refService: RefService,
    public CarrierInsuranceService: FUMCarrierInsuranceService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.getInsuranceTypes();
  }

  getInsuranceTypes() {
    this.refService.getInsuranceTypes().subscribe(
      (data) => {
        this.insuranceTypeList = data;
        this.resetForm();
      },
      (err) => (err) => {
        this.toastr.error(
          `<div class="mt-2" style="width: 100%;">Error: "${err.error
            ? !err.error.error
              ? err.error
              : err.error.error
            : err.message
          }"<div>`,
          'FUM Carrier Could Not Be Loaded!',
        );
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.fumCarrierFile &&
      changes.fumCarrierFile.currentValue &&
      !changes.fumCarrierFile.previousValue
    ) {
      this.resetForm();
    }
  }

  private newInsurance(): CarrierInsurance {
    return {
      carrierInsuranceID: 0,
      carrierID: null,
      fumCarrierID: null,
      status: '',
      refInsurance: null,
      underwriter: '',
      cargoLimitAmount: 0,
      deductibleAmount: 0,
      effectiveDate: null,
      expirationDate: null,
      cancelDate: null,
      effectiveDateFormatted: '',
      expirationDateFormatted: '',
      cancelDateFormatted: '',
      policyNumber: '',
      originationSource: InternalInsuranceOrigination,
    };
  }

  resetForm() {
    const newInsurance = this.newInsurance();
    this.addInsurance = !this.insurance;
    this.activeInsurance = this.insurance || newInsurance;

    if (this.fumCarrierFile) {
      this.insuranceForm = new FormGroup({
        status: new FormControl(this.activeInsurance.status),
        refInsurance: new FormControl(
          this.activeInsurance.refInsurance,
          Validators.required,
        ),
        underwriter: new FormControl(
          this.activeInsurance.underwriter,
          Validators.required,
        ),
        effectiveDate: new FormControl(
          this.activeInsurance.effectiveDate,
          Validators.required,
        ),
        expirationDate: new FormControl(
          this.activeInsurance.expirationDate,
          Validators.required,
        ),
        cancelDate: new FormControl(this.activeInsurance.cancelDate),
        policyNumber: new FormControl(
          this.activeInsurance.policyNumber,
          Validators.required,
        ),
        cargoLimitAmount: new FormControl(
          this.activeInsurance.cargoLimitAmount,
          [Validators.required, Validators.min(0), Validators.max(99999999.99)],
        ),
        deductibleAmount: new FormControl(
          this.activeInsurance.deductibleAmount,
          [Validators.required, Validators.min(0), Validators.max(99999999.99)],
        ),
      });
    }
  }

  cancel() {
    this.close.emit();
  }

  save() {
    if (this.insuranceForm.valid) {
      const formValue = this.insuranceForm.value;

      const CarrierInsurance = this.activeInsurance;

      CarrierInsurance.carrierID = null;
      CarrierInsurance.fumCarrierID = this.fumCarrierFile.carrierID;
      CarrierInsurance.refInsurance = formValue.refInsurance;
      CarrierInsurance.underwriter = formValue.underwriter;
      CarrierInsurance.cargoLimitAmount = formValue.cargoLimitAmount;
      CarrierInsurance.deductibleAmount = formValue.deductibleAmount;
      CarrierInsurance.effectiveDate = formValue.effectiveDate;
      CarrierInsurance.expirationDate = formValue.expirationDate;
      CarrierInsurance.cancelDate = formValue.cancelDate;
      CarrierInsurance.policyNumber = formValue.policyNumber;

      if (this.addInsurance) {
        this.CarrierInsuranceService.create(CarrierInsurance).subscribe(
          () => {
            this.toastr.success(
              'FUM Carrier Insurance Created Successfully!',
              'Success',
            );
            this.close.emit();
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
              }"<div>`,
              'FUM Carrier Insurance Could Not Be Created!',
            );
            this.close.emit();
          },
        );
      } else {
        this.CarrierInsuranceService.update(CarrierInsurance).subscribe(
          () => {
            this.toastr.success(
              'FUM Carrier Insurance Updated Successfully!',
              'Success',
            );
            this.close.emit();
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
              }"<div>`,
              'FUM Carrier Insurance Could Not Be Updated!',
            );
            this.close.emit();
          },
        );
      }
    }
  }

  get deductibleAmountField() {
    return this.insuranceForm.get('deductibleAmount');
  }
}
