import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ngx-cacheable';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Permission } from './Admin';
import { Configuration } from '../shared/configuration/Configuration';
import { AppState } from '../appstate.model';

@Injectable()
export class AdminService {
  config: Configuration;
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  /** Get list of all permissions */
  @Cacheable()
  getAllPermissions(): Observable<Permission[]> {
    return this.httpClient
      .get<Permission[]>(`${this.config.carrierServiceUrl}permission`)
      .pipe(
        map((x) => {
          var perms = [];
          x.forEach((y) => {
            perms.push({
              permissionID: y.permissionID,
              name: y.name,
              description: y.description,
              startDate: new Date(),
              endDate: new Date(2050, 1, 1),
            });
          });
          return perms;
        }),
      );
  }
}
