import { Action } from '@ngrx/store';
import { PreferredLaneState } from '../models/preferred-lane-state.model';
import { CarrierPreferredLane } from '../models/CarrierPreferredLane';

export const LOAD_PREFERREDLANES = '[CARRIER] Load Preferred Lanes';
export const LOAD_PREFERREDLANES_SUCCESS =
  '[CARRIER] Load Preferred Lanes Success';
export const LOAD_PREFERREDLANES_ERROR = '[CARRIER] Load Preferred Lanes Error';

export class LoadPreferredLanes implements Action {
  readonly type = LOAD_PREFERREDLANES;
  constructor(public carrierCode: string) { }
}

export class LoadPreferredLanesSuccess implements Action {
  readonly type = LOAD_PREFERREDLANES_SUCCESS;
  constructor(public state: PreferredLaneState) { }
}

export class LoadPreferredLanesError implements Action {
  readonly type = LOAD_PREFERREDLANES_ERROR;
  constructor(public errorMessage: string) { }
}

export const CREATE_PREFERRED_LANE = '[CARRIER] Create Preferred Lane';
export const EDIT_PREFERRED_LANE = '[CARRIER] Edit Preferred Lane';
export const DELETE_PREFERRED_LANE = '[CARRIER] Delete Preferred Lane';
export const UPDATE_PREFERRED_LANE_SUCCESS =
  '[CARRIER] Update Preferred Lane Success';
export const UPDATE_PREFERRED_LANE_ERROR =
  '[CARRIER] Update Preferred Lane Error';
export class CreatePreferredLane implements Action {
  readonly type = CREATE_PREFERRED_LANE;
  constructor(
    public carrierCode: string,
    public lane: CarrierPreferredLane,
  ) { }
}
export class EditPreferredLane implements Action {
  readonly type = EDIT_PREFERRED_LANE;
  constructor(
    public carrierCode: string,
    public lane: CarrierPreferredLane,
  ) { }
}
export class DeletePreferredLane implements Action {
  readonly type = DELETE_PREFERRED_LANE;
  constructor(
    public carrierCode: string,
    public laneId: number,
  ) { }
}

export class UpdatePreferredLaneSuccess implements Action {
  readonly type = UPDATE_PREFERRED_LANE_SUCCESS;
  constructor(public lanes: CarrierPreferredLane[]) { }
}
export class UpdatePreferredLaneError implements Action {
  readonly type = UPDATE_PREFERRED_LANE_ERROR;
  constructor(public errorMessage: string) { }
}

export type Actions =
  | LoadPreferredLanes
  | LoadPreferredLanesSuccess
  | LoadPreferredLanesError
  | CreatePreferredLane
  | EditPreferredLane
  | DeletePreferredLane
  | UpdatePreferredLaneSuccess
  | UpdatePreferredLaneError;
