import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TruckMatch } from '../../dto/TruckMatchDC';
import { TruckPostInventory, TruckPost } from '../../dto/TruckPostDC';
import { CarrierSummaryDC } from '../../../dto/CarrierSummaryDC';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { Permissions } from '../../../../shared/Enums/Permissions';
import { CarrierPostMatchService } from '../../services/carrier-truck-postmatch.service';
import { SubscriptionLike as ISubscription, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../appstate.model';
import { Configuration } from '../../../../shared/configuration/Configuration';
import {
  DataStateChangeEvent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CarrierOfferComponent } from '../../../../shared/carrier-offer/carrier-offer.component';
import { CarrierOfferService } from '../../../../shared/carrier-offer/carrier-offer.service';
import { CarrierOfferDC } from '../../../../shared/carrier-offer/CarrierOfferDC';
import * as moment from 'moment';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'truckload-carrier-truck-matching',
  templateUrl: './carrier-truck-matching.component.html',
  styleUrls: ['./carrier-truck-matching.component.scss'],
})
export class CarrierTruckMatchingComponent implements OnInit, OnDestroy {
  private static readonly GridFilterKey = 'Truckposting:gridStateKey';
  private subscriptions: Subscription[] = [];

  carrierCode: string;

  isRefreshingMatches = false;
  matchGridData: TruckMatch[] = [];
  postingGridData: TruckPost[];

  carrierInventoryList: TruckPostInventory[];
  carrierSummary: CarrierSummaryDC;
  carrierChanged$: ISubscription;

  config: Configuration;
  truckpostingState: DataStateChangeEvent = {
    take: 10,
    skip: 0,
    sort: [
      {
        field: 'name',
        dir: 'asc',
      },
    ],
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  gridData: GridDataResult;

  constructor(
    private carrierPostMatchService: CarrierPostMatchService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private carrierOfferService: CarrierOfferService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.carrierCode = params['id'];
    });

    this.subscriptions.push(
      this.carrierPostMatchService.isRefreshingMatches$.subscribe(
        (isRefreshing) => {
          this.isRefreshingMatches = isRefreshing;
        },
      ),
    );

    this.subscriptions.push(
      this.store
        .select((x) => x.Configuration)
        .subscribe((x) => {
          this.config = x;
        }),
    );

    this.subscriptions.push(
      this.store
        .select((x) => x.TruckPost.truckPosting)
        .subscribe((x) => {
          this.postingGridData = x.truckPostings;
          this.carrierInventoryList = x.inventory;
          this.refreshMatches();
        }),
    );

    this.subscriptions.push(
      this.store
        .select((x) => x.TruckPost.details)
        .subscribe((x) => {
          this.carrierSummary = x;
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get canCreateOffer(): boolean {
    return this.authService.can(Permissions.CreateOffer);
  }

  deleteMatch(posting: TruckPost): void {
    this.matchGridData = this.matchGridData.filter(
      (item) => item.truckPostingID != posting.truckPostingID,
    );
  }

  refreshMatches() {
    if (this.isRefreshingMatches === true) return;

    this.carrierPostMatchService
      .refreshAllMatches(this.postingGridData)
      .then((response) => {
        this.matchGridData = response;
        this.displayMatchings();
      })
      .catch((error) => {
        this.notificationService.show({
          content: `Error refreshing matches: ${error.message}`,
          cssClass: 'button-notification',
          animation: { type: 'fade', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
        });
      });
  }

  displayMatchings() {
    this.gridData = process(this.matchGridData, this.truckpostingState);
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.truckpostingState = state;
    this.setSettings(CarrierTruckMatchingComponent.GridFilterKey, state);
    this.gridData = process(this.matchGridData, this.truckpostingState);
  }

  setSettings(token: string, gridConfig: DataStateChangeEvent): void {
    localStorage.setItem(token, JSON.stringify(gridConfig));
  }

  getSettings(token: string): DataStateChangeEvent {
    const stateDefault = this.truckpostingState;

    const settings = localStorage.getItem(token);

    if (settings) {
      let filterSettings: any;

      filterSettings = JSON.parse(settings);

      filterSettings.filter.filters.forEach((f) => {
        f.filters.forEach((x) => {
          if (Date.parse(x.value)) {
            x.value = new Date(x.value);
          }
        });
      });

      return filterSettings;
    }

    return stateDefault;
  }

  goToCarrierOffer(dataItem: TruckMatch) {
    window.open(
      `${this.config.backofficeShipmentEditUrl}/shipment-edit/${dataItem.shipmentID}`,
    );
  }

  private showCreateOfferDialog(data: TruckMatch) {
    const dialogRef = this.dialogService.open({
      content: CarrierOfferComponent,
      width: 800,
    });

    const instance: CarrierOfferComponent = dialogRef.content
      .instance as CarrierOfferComponent;

    instance.shipmentID = data.shipmentID;
    instance.carrierSummary = this.carrierSummary;
    instance.currencyCode = data.carrierRateCurrencyCode;

    dialogRef.result.toPromise().then((dialogResult: any) => {
      if (!dialogResult.result) {
        return;
      }

      const effectiveDate = moment(new Date()).format('L');
      const currencyCode = dialogResult.result.currencyCode;

      Promise.all([
        this.carrierOfferService.getRate(effectiveDate, currencyCode),
        this.carrierOfferService.getUserDetails().toPromise(),
      ])
        .then((results) => {
          const rate = results[0];
          const userDetails = results[1];
          const convertedBuyRate = dialogResult.result.buyRate / rate;
          let offer = {
            carrierCode: this.carrierSummary.carrierCode,
            carrierName: this.carrierSummary.name,
            dotNumber: this.carrierSummary.dotNumber,
            mcNumber: this.carrierSummary.mcNumber,
            scac: this.carrierSummary.scac,
            currencyCode: currencyCode,
            carrierChargeInUSD: convertedBuyRate,
            carrierCharge: dialogResult.result.buyRate,
            customerCharge: 0,
            notes: dialogResult.result.quoteNote,
          } as CarrierOfferDC;

          return this.carrierOfferService
            .createOffer(data.shipmentID, userDetails, offer)
            .toPromise();
        })
        .then(
          (result) => {
            this.showOfferCreatedNotification(result as CarrierOfferDC);
          },
          (err) => {
            this.showOfferErrorNotification(err);
          },
        );
    });
  }

  private showOfferCreatedNotification(offer: CarrierOfferDC): void {
    this.notificationService.show({
      content: 'Offer has been created for ' + offer.carrierName,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 3000 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'info', icon: true },
    });
  }

  private showOfferErrorNotification(err: any): void {
    this.notificationService.show({
      content: `Error creating offer: ${err.message}`,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 3000 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'error', icon: true },
    });
  }
}
