export class FUMNewCarrier {
  carrierID: number;
  carrierName: string;
  carrierDbaName: string;
  carrierCode: string;
  customerName: string;
  isPay: boolean;
  status: string;
  mainAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
  };
  stateID: string;
  stateIDType: string;
  dotNumber: string;
  mcNumber: string;
  scac: string;

  constructor(data: any) {
    this.carrierName = data.legalName;
    this.carrierDbaName = data.dbaName;
    this.customerName = data.customerName;
    this.status = data.status;
    this.mainAddress = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      stateProvince: data.state,
      postalCode: data.postalCode
    };
    this.stateID = data.stateID;
    this.stateIDType = data.stateIDType;
    this.dotNumber = data.dotNumber;
    this.mcNumber = data.mcNumber;
    this.scac = data.scac;
  }
}
