import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { identitiesValidator } from '../../../directives/carrier-id-validation.directive';
import { StateList } from '../../../../shared/Enums/StateList';
import { FUMCarrierService } from '../../../../Services/fum-carrier.service';
import { FUMUpdateCarrier } from '../../../models/FUMUpdateCarrier';
import { FUMCarrierIdentityService } from '../../../services/fum-carrier-identity.service';
import {
  FUMCarrierIdentityBase,
  FUMUpdateCarrierIdentity
} from '../../../models/FUMCarrierIdentity';

@Component({
  selector: 'fum-carrier-identity-component',
  templateUrl: './fum-carrier-identity.component.html'
})
export class FUMCarrierIdentityComponent implements OnInit {
  @Input()
  fumCarrierFile: FUMCarrier;

  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();

  identityForm: FormGroup;
  stateProvinceList = Object.keys(StateList);
  loadingCounter: number = 0;

  constructor(
    private fumCarrierService: FUMCarrierService,
    private fumCarrierIdentityService: FUMCarrierIdentityService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.resetForm();
  }

  public resetForm(): void {
    this.identityForm = new FormGroup(
      {
        legalName: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.carrierName : null,
          Validators.required
        ),
        customerName: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.customerName : null,
          Validators.required
        ),
        dbaName: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.carrierDbaName : null
        ),
        dotNumber: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.dotNumber : null
        ),
        mcNumber: new FormControl(
          this.removeDocketPrefix(
            this.fumCarrierFile ? this.fumCarrierFile.mcNumber : null,
            'MC'
          )
        ),
        mxNumber: new FormControl(
          this.removeDocketPrefix(
            this.fumCarrierFile ? this.fumCarrierFile.mxNumber : null,
            'MX'
          )
        ),
        ffNumber: new FormControl(
          this.removeDocketPrefix(
            this.fumCarrierFile ? this.fumCarrierFile.ffNumber : null,
            'FF'
          )
        ),
        scac: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.scac : null
        ),
        stateID: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.stateID : null
        ),
        stateIDType: new FormControl(
          this.fumCarrierFile ? this.fumCarrierFile.stateIDType : null
        )
      },
      { validators: [identitiesValidator] }
    );
  }

  submit(): void {
    const updateCarrierIdentity = {
      carrierCode: this.fumCarrierFile.carrierCode,
      identities: []
    } as FUMUpdateCarrierIdentity;
    updateCarrierIdentity.identities = this.appendIfValuePresent(
      this.identityForm.value.dotNumber,
      'DOT Number',
      updateCarrierIdentity.identities
    );
    updateCarrierIdentity.identities = this.appendIfValuePresent(
      this.addDocketPrefix(this.identityForm.value.mcNumber, 'MC'),
      'MC Number',
      updateCarrierIdentity.identities
    );
    updateCarrierIdentity.identities = this.appendIfValuePresent(
      this.addDocketPrefix(this.identityForm.value.mxNumber, 'MX'),
      'MX Number',
      updateCarrierIdentity.identities
    );
    updateCarrierIdentity.identities = this.appendIfValuePresent(
      this.addDocketPrefix(this.identityForm.value.ffNumber, 'FF'),
      'FF Number',
      updateCarrierIdentity.identities
    );
    updateCarrierIdentity.identities = this.appendIfValuePresent(
      this.identityForm.value.scac,
      'SCAC',
      updateCarrierIdentity.identities
    );
    updateCarrierIdentity.identities = this.appendIfValuePresent(
      this.identityForm.value.stateID,
      this.identityForm.value.stateIDType,
      updateCarrierIdentity.identities
    );

    this.loadingCounter++;
    this.fumCarrierIdentityService.update(updateCarrierIdentity).subscribe(
      () => {
        this.loadingCounter--;
        this.toastr.success(
          'FUM Carrier Identities Updated Successfully!',
          'Success'
        );
        this.cancel();
      },
      (err) => {
        this.loadingCounter--;
        this.toastr.error(
          `<div class="mt-2" style="width: 100%;">Error: "${
            err.error
              ? !err.error.error
                ? err.error
                : err.error.error
              : err.message
          }"<div>`,
          'FUM Carrier Identities Could Not Be Updated!'
        );
        this.cancel();
      }
    );

    const updatedCarrier = Object.assign(
      this.fumCarrierFile,
      this.identityForm.value
    ) as FUMUpdateCarrier;

    this.loadingCounter++;
    this.fumCarrierService
      .update(updatedCarrier, this.fumCarrierFile.carrierID)
      .subscribe(
        () => {
          this.loadingCounter--;
          this.toastr.success('FUM Carrier Updated Successfully!', 'Success');
          this.cancel();
        },
        (err) => {
          this.loadingCounter--;
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            'FUM Carrier Could Not Be Updated!'
          );
          this.cancel();
        }
      );
  }

  private appendIfValuePresent(
    value: any,
    type: string,
    identities: FUMCarrierIdentityBase[]
  ): FUMCarrierIdentityBase[] {
    if (value) {
      identities.push({
        value: value,
        type: type
      });
    }
    return identities;
  }

  cancel(): void {
    if (this.loadingCounter <= 0) {
      this.close.emit();
    }
  }

  private removeDocketPrefix(docketNumber: string, prefix: string): string {
    return docketNumber && docketNumber.toUpperCase().startsWith(prefix)
      ? docketNumber.substr(prefix.length)
      : docketNumber;
  }

  private addDocketPrefix(docketNumber: string, prefix: string): string {
    return docketNumber && !docketNumber.toUpperCase().startsWith(prefix)
      ? prefix + docketNumber
      : docketNumber;
  }

  get legalName() {
    return this.identityForm.get('legalName');
  }

  get customerName() {
    return this.identityForm.get('customerName');
  }

  get dotNumber() {
    return this.identityForm.get('dotNumber');
  }

  get mcNumber() {
    return this.identityForm.get('mcNumber');
  }

  get stateID() {
    return this.identityForm.get('stateID');
  }

  get stateIDType() {
    return this.identityForm.get('stateIDType');
  }

  get scac() {
    return this.identityForm.get('scac');
  }
}
