export const CarrierContactVerificationStatus = {
  Unverified: 'Unverified',
  Pending: 'Pending',
  Verified: 'Verified',
  Rejected: 'Rejected',
} as const;

export const CarrierContactVerificationStatusList = Object.freeze(
  Object.values(CarrierContactVerificationStatus),
);

export type CarrierContactVerificationStatus =
  keyof typeof CarrierContactVerificationStatus;

export type CarrierContact = {
  carrierContactID: number;
  carrierID: number;
  fumCarrierID: number;
  carrierCode: string;
  contactID: number;
  refContactID: number;
  isPrimary: boolean;
  name: string;
  title: string;
  phone: string;
  cell: string;
  fax: string;
  email: string;
  contactType: string;
  isAuthorizedContact: boolean;
  verificationStatus: CarrierContactVerificationStatus;
  originationSource: string;
};
