import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';

import { CertificationService } from './certification.service';
import { RefService } from '../../../shared/references/ref.service';
import { CommonService } from '../../../Services/common.service';

import { Permissions } from '../../../shared/Enums/Permissions';
import { AuthService } from '../../../authentication/_services/auth.service';
import { RefCertification } from '../../../shared/references/RefCertification';
import { CarrierCertification } from '../../models/CarrierCertification';

// credit: https://netbasal.com/handling-multiple-checkboxes-in-angular-forms-57eb8e846d21

@Component({
  selector: 'truckload-carrier-file-certifications',
  templateUrl: './carrier-file-certifications.component.html',
  styleUrls: ['./carrier-file-certifications.component.scss'],
})
export class CarrierFileCertificationsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private certificationService: CertificationService,
    private refService: RefService,
    public commonService: CommonService,
  ) { }

  @Input()
  BGCode: Observable<string>;

  private currentBGCode: string;

  public editMode = false;
  public options: any[];
  public carrierCertifications: CarrierCertification[];
  public formCertifications: FormGroup = new FormGroup({});

  get canEdit(): boolean {
    return this.authService.can(Permissions.EditCertifications);
  }

  ngOnInit() {
    this.BGCode.subscribe((data) => {
      this.currentBGCode = data;
      this.formCertifications = new FormGroup({});
      this.loadCarrierCertifications();
    });
  }

  loadCarrierCertifications(): void {
    this.certificationService.getCertifications(this.currentBGCode).subscribe(
      (data) => {
        this.carrierCertifications = data.certifications;
      },
      (err) => { },
      () => {
        this.editMode = false;
      },
    );
  }

  loadAllCertifications(): void {
    this.refService.getCertifications().subscribe((data) => {
      this.options = data.map((a) => {
        return {
          id: a.refCertificationID,
          value: a.type,
          isChecked:
            this.carrierCertifications.filter(
              (b) => b.refCertificationID === a.refCertificationID,
            ).length > 0,
        };
      });

      this.formCertifications = this.fb.group({
        certs: this.buildFormControls(),
      });
    });
  }

  buildFormControls() {
    const arr = this.options.map((svc) => {
      return this.fb.control(svc.isChecked);
    });
    return this.fb.array(arr);
  }

  get certifications() {
    return this.formCertifications && this.formCertifications.get('certs');
  }

  edit(): void {
    this.loadAllCertifications();
    this.editMode = true;
  }

  save() {
    const selectedRefCerts = this.formCertifications.value.certs
      .map((s: any, i: number) => {
        return s
          ? {
            refCertificationID: this.options[i].id,
            type: this.options[i].value,
          }
          : undefined;
      })
      .filter((a: any) => a !== undefined);

    this.certificationService
      .saveCarrierCertifications(this.currentBGCode, selectedRefCerts)
      .subscribe((data) => {
        this.loadCarrierCertifications();
      });
  }

  cancel(): void {
    this.editMode = false;
  }
}
