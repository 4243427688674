import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierNote } from '../models/CarrierNote';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class FUMCarrierNoteService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  save(carrierNote: CarrierNote): Observable<CarrierNote> {
    return this.http.post<CarrierNote>(
      `${this.config.carrierServiceUrl}FUM/CarrierNote`,
      carrierNote,
    );
  }

  delete(carrierNoteID: number): Observable<any> {
    return this.http.delete(
      `${this.config.carrierServiceUrl}FUM/CarrierNote/${carrierNoteID}`,
    );
  }
}
