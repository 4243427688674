import {
  Component,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  WindowCloseResult,
  WindowRef,
  WindowService,
} from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-admin-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @ViewChild('templateContent', { static: true }) template: TemplateRef<any>;
  @Output() closeMethod = new EventEmitter<any>();
  @Input() buttonContainerClasses: string = '';
  private windowRef: WindowRef;
  opened: boolean = false;

  constructor(private windowService: WindowService) {}

  showConfirmation(optionsModal: any): void {
    this.windowRef = this.windowService.open({
      title: optionsModal.title,
      width: optionsModal.width,
      height: optionsModal.height,
      top: 100,
      content: this.template,
    });

    this.windowRef.result.subscribe((result) => {
      if (result instanceof WindowCloseResult) {
        this.opened = false;
        this.closeMethod.emit();
      }
    });

    this.opened = true;
  }

  closeModal(): void {
    this.windowRef.close();
    this.opened = false;
  }

  get maxHeight(): string {
    const win = this.windowRef.window.instance;
    let vSpace = window.innerHeight - 300;
    if (win.height) {
      // 170 is the height of the title bar and the button container
      vSpace = win.height - 170;
    }
    return `${vSpace}px`;
  }
}
