import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CarrierCertification } from '../../../models/CarrierCertification';
import { RefService } from '../../../../shared/references/ref.service';
import { RefCertification } from '../../../../shared/references/RefCertification';
import { FumCarrierCertificationService } from '../../../../carrier/services/fum-carrier-certification.service';
import { UpdateCarrierCertification } from '../../../../carrier/models/UpdateCarrierCertifications';

@Component({
  selector: 'fum-carrier-certification-component',
  templateUrl: './fum-carrier-certifications.component.html'
})
export class FUMCarrierCertificationComponent implements OnInit {
  @Input()
  fumCarrierFile: FUMCarrier;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  certificationForm: FormGroup;
  public certificationOptions: RefCertification[];
  public selectedCertifications: string[];

  constructor(
    private refService: RefService,
    private fumCertificationService: FumCarrierCertificationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.loadAllCertifications();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.fumCarrierFile &&
      changes.fumCarrierFile.currentValue &&
      !changes.fumCarrierFile.previousValue &&
      !changes.fumCarrierFile.previousValue
    ) {
      if (this.fumCarrierFile) {
        this.resetForm();
      }
    }
  }

  loadAllCertifications(): void {
    this.refService.getCertifications().subscribe((data) => {
      this.certificationOptions = data;
    });
  }

  createNewCertification(): CarrierCertification {
    return {
      carrierID: null,
      fumCarrierID: null,
      type: null,
      carrierCertificationID: null,
      refCertificationID: null
    };
  }

  public resetForm(): void {
    this.selectedCertifications = this.fumCarrierFile.carrierCertifications;
  }

  submit(): void {
    let updateCarrierCertifications: UpdateCarrierCertification = {
      carrierCertifications: this.selectedCertifications,
      fumCarrierID: this.fumCarrierFile.carrierID,
      carrierID: null,
      carrierCode: null
    };

    this.fumCertificationService.save(updateCarrierCertifications).subscribe(
      (data) => {
        this.toastr.success('Certifications saved successfully');
        this.close.emit();
      },
      (err) => {
        this.toastr.error(
          `<div class="mt-2" style="width: 100%;">Error: "
            ${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
          `FUM Carrier Certifications Could Not Be Saved!`
        );
      }
    );
  }

  cancel(): void {
    this.close.emit();
  }
}
