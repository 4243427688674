import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CurrencyRate } from '../shared/Models/CurrencyRate';
import { Configuration } from '../shared/configuration/Configuration';
import { AppState } from '../appstate.model';
import { UserDetails } from '../shared/carrier-offer/UserDetails';
import { AuthService } from '../authentication/_services/auth.service';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  async getCurrency(
    effectiveDate: string,
  ): Promise<Observable<CurrencyRate[]>> {
    let userDetails = await this.getUserDetails().toPromise();
    let headers = new HttpHeaders({
      UserID: userDetails.userID.toString(),
      UserName: userDetails.userName,
      EnterpriseID: userDetails.enterpriseID.toString(),
    });
    // Hack to circumvent expectations of v1 url
    const shipmentV2Url = this.config.shipmentServiceUrl.replace('v1', 'v2');
    return this.http.get<CurrencyRate[]>(
      `${shipmentV2Url}currency?effectiveDate=${effectiveDate}`,
      { headers: headers },
    );
  }

  @Cacheable()
  getUserDetails(): Observable<UserDetails> {
    const headers = new HttpHeaders({
      UserName: this.authService.loggedAppUser.appUserName,
    });
    return this.http.get<UserDetails>(
      `${this.config.shipmentServiceUrl}user/current`,
      { headers: headers },
    );
  }
}
