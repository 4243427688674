import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';
import { Store } from '@ngrx/store';
import { CarrierOfferDC } from '../../models/CarrierOfferDC';
import { AuthService } from '../../../authentication/_services/auth.service';
import { GetShipmentByPrimaryReferenceDC } from './GetShipmentByPrimaryReferenceDC';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';
import { UserDetails } from '../../../shared/carrier-offer/UserDetails';

@Injectable()
export class CarrierProfileOfferService {
  config: Configuration;
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  findShipment(
    bgNumber: number,
    userDetails: UserDetails,
  ): Observable<GetShipmentByPrimaryReferenceDC> {
    const headers = new HttpHeaders({
      UserID: userDetails.userID.toString(),
      UserName: userDetails.userName,
      EnterpriseID: userDetails.enterpriseID.toString(),
    });

    return this.http.get<GetShipmentByPrimaryReferenceDC>(
      `${this.config.shipmentServiceUrl}shipment/getByPrimaryReference/${bgNumber}`,
      {
        headers: headers,
      },
    );
  }

  createOffer(
    shipmentId: number,
    userDetails: UserDetails,
    offer: CarrierOfferDC,
  ): Observable<any> {
    const headers = new HttpHeaders({
      UserID: userDetails.userID.toString(),
      UserName: userDetails.userName,
      EnterpriseID: userDetails.enterpriseID.toString(),
    });

    return this.http.post<any>(
      `${this.config.shipmentServiceUrl}shipment/${shipmentId}/rate`,
      offer,
      { headers: headers },
    );
  }

  @Cacheable()
  getUserDetails(): Observable<any> {
    const headers = new HttpHeaders({
      UserName: this.authService.loggedAppUser.appUserName,
    });
    return this.http.get<any>(`${this.config.shipmentServiceUrl}user/current`, {
      headers: headers,
    });
  }
}
