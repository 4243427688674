import { Injectable } from '@angular/core';
import { Configuration } from '../../../../app/shared/configuration/Configuration';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app/appstate.model';
import { LoadHistoryDC } from '../../models/LoadHistoryDC';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CarrierLoadHistoryService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getLoadHistory(carrierCode: string): Observable<LoadHistoryDC[]> {
    return this.http.get<LoadHistoryDC[]>(
      `${this.config.carrierServiceUrl}search/carrier/${carrierCode}/loadhistory`,
    );
  }
}
