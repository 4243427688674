import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { ConfigurationService } from './shared/configuration/configuration.service';
import { CommonService } from './Services/common.service';
import { AppUserService } from './shared/appuser-autocomplete/appuser.service';
import { JwtInterceptor } from './authentication/_http/jwt.interceptor';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AddressLookupService } from './Services/address-lookup.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AppInsightService } from './app.insight.service';
import { BreadcrumbModule } from './breadcrumbs/breadcrumb.module';
import { AppRoutingModule } from './app-routing.module';
import { NewAdminModule } from './admin-v2/admin.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { SearchModule } from './carrier-search/search.module';

// Core app components START
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HealthComponent } from './health/health.component';
import { ProfileComponent } from './profile/profile.component';
import { NotFoundComponent } from './not-found.component';
import { VerifyComponent } from './verify/verify.component';
// Core app components END

// Core app services START
import { MenuService } from './header-menu/MenuService';
import { AuthService } from './authentication/_services/auth.service';
import { CarrierFileManagerGuard } from './shared/guards/carrier/carrier-file-manager.guard';
import { FUMCarrierFileManagerGuard } from './shared/guards/fum-carrier/fum-carrier-file-manager.guard';
import { CarrierManagerGuard } from './shared/guards/carrier/carrier-manager.guard';
import { NewCarrierManagerGuard } from './shared/guards/carrier/new-carrier-manager.guard';
import { AdminManagerGuard } from './shared/guards/admin/admin-manager.guard';
import { LaneLoadHistoryGuard } from './shared/guards/misc/lane-load-history.guard';
import { UserManagerGuard } from './shared/guards/admin/user-manager.guard';
import { CurrencyService } from './Services/currency.service';
import { StoreModule, Store, State } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { appReducer } from './app.reducer';
import { AppState } from './appstate.model';
import * as AppActions from './app.actions';
import { featureFlagReducer } from './shared/feature-flag/feature-flag.reducer';
import { ToastrModule } from 'ngx-toastr';
// Core app services END

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    HealthComponent,
    ProfileComponent,
    NotFoundComponent,
    VerifyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ButtonsModule,
    GridModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AuthenticationModule,
    BreadcrumbModule,
    PortfolioModule,
    SearchModule,
    NewAdminModule,
    StoreModule.forRoot({
      Configuration: appReducer,
      FeatureFlags: featureFlagReducer,
    }),
    EffectsModule.forRoot([AppEffects]),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true,
      timeOut: 7000,
    }),
  ],
  providers: [
    ConfigurationService,
    AppInsightService,
    AppUserService,
    CommonService,
    AddressLookupService,
    MenuService,
    AuthService,
    CarrierFileManagerGuard,
    FUMCarrierFileManagerGuard,
    CarrierManagerGuard,
    NewCarrierManagerGuard,
    AdminManagerGuard,
    LaneLoadHistoryGuard,
    UserManagerGuard,
    CurrencyService,
    {
      // Here we request that configuration loading be done at app-
      // initialization time (prior to rendering)
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigurationService, store: Store<AppState>) => () => {
          return new Promise<void>((resolve, reject) => {
            configService.loadConfigurationData().subscribe((x) => {
              store.dispatch(new AppActions.LoadAppConfigSuccess(x));
              resolve();
            });
          });
        },
      deps: [ConfigurationService, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
