import { distinct } from '@progress/kendo-data-query';
// import { Subject, Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { HttpClient } from '@angular/common/http';
// import { AddressMatch } from '../shared/address-lookup/address';
// import { ConfigurationService } from './configuration.service';

export class CommonService {
  constructor() {}

  public distinctPrimitive(primitiveList: any[], fieldName: string): any {
    return distinct(primitiveList, fieldName).map((item) => item[fieldName]);
  }

  splitArrayIntoRows(array: any[], rowSize: number): any[] {
    const newArr = [];
    for (let i = 0; i < array.length; i += rowSize) {
      newArr.push(array.slice(i, i + rowSize));
    }
    return newArr;
  }

  formatPhone(tel: string): string {
    // i tried to be fancy and make it work neatly but couldn't figure it out
    // so I put his in as a way to do it for now because i could build it in 5min - Rob 5/16/2018
    if (!tel) {
      return '';
    }
    const value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country, city, number;
    let extension = '';

    switch (value.length) {
      case 10:
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3, 10);
        break;

      case 11:
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3, 10);
        extension = value.slice(10);
        break;

      case 12:
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3, 10);
        extension = value.slice(10);
        break;
      case 13:
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3, 10);
        extension = value.slice(10);
        break;

      case 14:
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3, 10);
        extension = value.slice(10);
        break;
      case 15:
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3, 10);
        extension = value.slice(10);
        break;
      default:
        return tel;
    }

    if (country === 1) {
      country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    let result = country + ' (' + city + ') ' + number;
    result += extension ? ' x ' + extension : '';

    return result.trim();
  }
}
