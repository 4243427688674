import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentication/_services/auth.service';
import { UserService } from '../authentication/_services/user.service';
import { AppUser } from '../authentication/_models/AppUser';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'truckload-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {}

  user: AppUser;
  userForm: FormGroup;

  ngOnInit() {
    this.userForm = new FormGroup({
      appUserName: new FormControl(null),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      email: new FormControl(null),
      phone: new FormControl(null),
      fax: new FormControl(null),
    });

    this.userService.getCurrent().subscribe((appUser) => {
      this.authService.loggedAppUser = appUser;
      this.user = appUser;
      this.userForm.patchValue({
        appUserName: appUser.appUserName,
        firstName: appUser.firstName,
        lastName: appUser.lastName,
        email: appUser.email,
        phone: appUser.phone,
        fax: appUser.fax,
      });
    });
  }
}
