import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from '../../../Services/page.service';
import { CarrierSearchParameters } from '../../models/CarrierSearchParameters';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';
import * as SearchActions from '../../actions/search.actions';
import { Configuration } from '../../../shared/configuration/Configuration';
import { ModalComponent } from '../../../modal/modal.component';
import { NewCarrierFileComponent } from '../../../carrier/new-carrier-file/new-carrier-file.component';

@Component({
  selector: 'truckload-carrier-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
})
export class CarrierSearchComponent implements OnInit {
  @ViewChild('newCarrierModal', { static: false })
  newCarrierModal: ModalComponent;
  @ViewChild('newCarrierForm', { static: false })
  newCarrierForm: NewCarrierFileComponent;
  config: Configuration;
  carrierSearchParams: CarrierSearchParameters;

  showLoading: boolean;
  showNameSearch: boolean;
  showIDSearch: boolean;
  showLocationSearch: boolean;
  showLaneSearch: boolean;
  showTruckpostingSearch: boolean;

  showCarrierGrid: boolean;
  showLaneGrid: boolean;
  showTruckpostingGrid: boolean;

  canSearchByName: boolean;
  canSearchByID: boolean;
  canSearchByLocation: boolean;
  canSearchByLane: boolean;
  canCreateManualCarrier: boolean;
  canViewPostMatch: boolean;
  canSearchByTruckposting: boolean;

  constructor(
    private pageService: PageService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit() {
    this.pageService.setPageTitle('Carrier Search');

    this.store
      .select((x) => x.Search.loadingSearch)
      .subscribe((x) => {
        this.showLoading = x;
      });

    this.store
      .select((x) => x.Search.carrierSearchParams)
      .subscribe((x) => {
        if (x == null) return;
        this.carrierSearchParams = x;
        this.selectName();
      });

    this.store
      .select((x) => x.Search)
      .subscribe((x) => {
        this.canSearchByName = x.canSearchByName;
        this.canSearchByID = x.canSearchByID;
        this.canSearchByLocation = x.canSearchByLocation;
        this.canSearchByLane = x.canSearchByLane;
        this.canCreateManualCarrier = x.canCreateManualCarrier;
        this.canViewPostMatch = x.canViewPostMatch;
        this.canSearchByTruckposting = x.canSearchByTruckposting;

        this.showCarrierGrid = x.showCarrierGrid;
        this.showLaneGrid = x.showLaneGrid;
        this.showTruckpostingGrid = x.showTruckpostingGrid;
        this.showNameSearch = x.showNameSearch;
        this.showIDSearch = x.showIDSearch;
        this.showLocationSearch = x.showLocationSearch;
        this.showLaneSearch = x.showLaneSearch;
        this.showTruckpostingSearch = x.showTruckpostingSearch;
      });

    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });

    this.store.dispatch(new SearchActions.SearchLoad());
  }

  ngAfterViewInit() {
    const urlPaths = this.router.url.split('/');
    const urlPath = urlPaths[urlPaths.length - 1];

    if (urlPath === 'new') {
      this.openCreateCarrierModal();
    }
  }

  get hasSearchPermission(): boolean {
    return (
      this.canSearchByName ||
      this.canSearchByID ||
      this.canSearchByLocation ||
      this.canSearchByLane ||
      this.canSearchByTruckposting
    );
  }

  openCreateCarrierModal(): void {
    const optionsModal = {
      width: 1000,
      title: 'New Carrier Form',
    };

    this.newCarrierForm.resetForm();
    this.newCarrierModal.showConfirmation(optionsModal);
  }

  syncDisabled(): boolean {
    return this.config.disableCarrierSync === 'true';
  }

  selectName(): void {
    this.store.dispatch(
      new SearchActions.SelectSearchType(this.carrierSearchParams),
    );
  }
}
