import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RefService } from '../../../shared/references/ref.service';
import { StateList } from '../../../shared/Enums/StateList';
import { PageService } from '../../../Services/page.service';
import { NewCarrier } from '../../models/NewCarrier';
import { FUMNewCarrier } from '../../models/FUMNewCarrier';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { identitiesValidator } from '../../directives/carrier-id-validation.directive';
import { Geolocation } from '../../../shared/Models/Geolocation';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import { FUMCarrierService } from './../../../Services/fum-carrier.service';

@Component({
  selector: 'truckload-new-fum-carrier',
  templateUrl: './new-fum-carrier.component.html',
  styleUrls: ['./new-fum-carrier.component.scss'],
})
export class NewFUMCarrierComponent implements OnInit {
  @Input() opened: boolean = false;
  addNewFUMCarrierClicked: boolean = false;
  addSelectedCarrierClicked: boolean = false;
  addNewFUMCustomerClicked: boolean = false;
  addSelectedCustomerClicked: boolean = false;
  legalNameTypedValue: string = '';
  customerNameTypedValue: string = '';
  googleSearchLocation: Geolocation = {} as Geolocation;
  manualLocationChecked = false;
  stateProvinceList = Object.keys(StateList);
  fumCarrierForm: FormGroup;
  identitiesForm: FormGroup;
  fumCarrierNameSuggestions$: Observable<any[]>;
  fumCustomerNameSuggestions$: Observable<any[]>;
  private fumCarrierNameSearchTerms = new BehaviorSubject<string>('');
  private fumCustomerNameSearchTerms = new BehaviorSubject<string>('');
  serverError: string;
  constructor(
    public fumCarrierService: FUMCarrierService,
    public refService: RefService,
    private pageService: PageService,
    private router: Router,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.pageService.setPageTitle('New Manual FUM Carrier');

    this.reset();
  }

  public reset() {
    this.resetForm();

    this.legalNameTypedValue = '';
    this.addNewFUMCarrierClicked = false;
    this.addSelectedCarrierClicked = false;
  }

  public resetForm() {
    this.identitiesForm = new FormGroup(
      {
        dotNumber: new FormControl(null),
        mcNumber: new FormControl(null),
        stateID: new FormControl(null),
        stateIDNumberState: new FormControl(null),
      },
      { validators: [identitiesValidator] },
    );
    this.fumCarrierForm = new FormGroup({
      legalName: new FormControl(null, Validators.required),
      customerName: new FormControl(null, Validators.required),
      dbaName: new FormControl(null),
      isPay: new FormControl(false),
      addressLine1: new FormControl(null, Validators.required),
      addressLine2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      identities: this.identitiesForm,
    });

    this.fumCarrierNameSearchTerms = new BehaviorSubject<string>('');
    this.fumCustomerNameSearchTerms = new BehaviorSubject<string>('');

    this.fumCarrierNameSuggestions$ = this.fumCarrierNameSearchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        return this.fumCarrierService.searchFUMCarriers(
          term,
          this.addSelectedCarrierClicked,
          this.addNewFUMCarrierClicked,
        );
      }),
      catchError((error) => {
        console.error(error);
        return [];
      }),
    );

    this.fumCustomerNameSuggestions$ = this.fumCustomerNameSearchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        return this.fumCarrierService.searchFUMCustomer(
          term,
          this.addSelectedCustomerClicked,
          this.addNewFUMCustomerClicked,
        );
      }),
      catchError((error) => {
        console.error(error);
        return [];
      }),
    );

    this.fumCarrierForm.get('legalName').valueChanges.subscribe((term) => {
      return this.fumCarrierNameSearchTerms.next(term);
    });

    this.fumCarrierForm.get('customerName').valueChanges.subscribe((term) => {
      return this.fumCustomerNameSearchTerms.next(term);
    });
  }

  // FUM Legal Name
  public legalNameInput() {
    this.addSelectedCarrierClicked = false;
    this.legalNameTypedValue = this.legalName.value;
  }

  public onLegalNameBlur() {
    // Clear the value after a short delay to allow the click event, 'addNewFUMCarrier'
    setTimeout(() => {
      if (!this.addSelectedCarrierClicked && !this.addNewFUMCarrierClicked) {
        this.legalName.setValue('');
        this.legalNameTypedValue = '';
      }
    }, 500);
  }

  public addNewFUMCarrier() {
    this.addNewFUMCarrierClicked = true;
    this.addSelectedCarrierClicked = false;
    this.legalName.setValue(this.legalNameTypedValue);
    this.fumCarrierNameSearchTerms.next('');
  }

  public selectCarrierName(carrierName: string) {
    this.addNewFUMCarrierClicked = false;
    this.addSelectedCarrierClicked = true;
    this.legalName.setValue(carrierName);
  }

  public cancelAddNewFUMCarrierClicked() {
    this.addNewFUMCarrierClicked = false;
    this.addSelectedCarrierClicked = false;
    this.legalName.setValue('');
    this.legalNameTypedValue = '';
  }
  // END FUM Legal Name

  // FUM Customer Name
  public customerNameInput() {
    this.addSelectedCustomerClicked = false;
    this.customerNameTypedValue = this.customerName.value;
  }

  public onCustomerNameBlur() {
    // Clear the value after a short delay to allow the click event, 'addNewFUMCarrier'
    setTimeout(() => {
      if (!this.addSelectedCustomerClicked && !this.addNewFUMCustomerClicked) {
        this.customerName.setValue('');
        this.customerNameTypedValue = '';
      }
    }, 500);
  }
  public addNewFUMCustomer() {
    this.addNewFUMCustomerClicked = true;
    this.addSelectedCustomerClicked = false;
    this.customerName.setValue(this.customerNameTypedValue);
    this.fumCustomerNameSearchTerms.next('');
  }

  public selectCustomerName(customerName: string) {
    this.addNewFUMCustomerClicked = false;
    this.addSelectedCustomerClicked = true;
    this.customerName.setValue(customerName);
  }

  public cancelAddNewFUMCustomerClicked() {
    this.addNewFUMCustomerClicked = false;
    this.addSelectedCustomerClicked = false;
    this.customerNameTypedValue = '';
    this.customerName.setValue('');
  }
  // END FUM Customer Name

  get legalName() {
    return this.fumCarrierForm.get('legalName');
  }

  get customerName() {
    return this.fumCarrierForm.get('customerName');
  }

  get dbaName() {
    return this.fumCarrierForm.get('dbaName');
  }

  get isPay() {
    return this.fumCarrierForm.get('isPay');
  }

  get addressLine1() {
    return this.fumCarrierForm.get('addressLine1');
  }

  get city() {
    return this.fumCarrierForm.get('city');
  }

  get state() {
    return this.fumCarrierForm.get('state');
  }

  get postalCode() {
    return this.fumCarrierForm.get('postalCode');
  }

  get dotNumber() {
    return this.identitiesForm.get('dotNumber');
  }

  get mcNumber() {
    return this.identitiesForm.get('mcNumber');
  }

  get stateID() {
    return this.identitiesForm.get('stateID');
  }

  get stateIDNumberState() {
    return this.identitiesForm.get('stateIDNumberState');
  }

  /* set form values from google location search */
  public locationChanged(location: Geolocation) {
    this.fumCarrierForm.patchValue({
      city: location.city,
      state: location.stateProvince,
      postalCode: location.postalCode,
    });

    this.city.markAsTouched();
    this.state.markAsTouched();
    this.postalCode.markAsTouched();
  }

  get canEditManualLocation(): boolean {
    return this.authService.can(Permissions.EditManualLocation);
  }

  /* clears location input controls when clicking manual location entry checkbox */
  public useManualLocationChanged() {
    this.fumCarrierForm.patchValue({
      city: null,
      state: null,
      postalCode: null,
    });

    this.city.markAsPristine();
    this.state.markAsPristine();
    this.postalCode.markAsPristine();
  }

  submit() {
    const newCarrier = Object.assign(
      {},
      this.fumCarrierForm.value,
    ) as NewCarrier;
    const identities = Object.assign(
      {},
      this.identitiesForm.value,
    ) as NewCarrier;

    newCarrier.dotNumber = identities.dotNumber;
    newCarrier.mcNumber = identities.mcNumber;
    newCarrier.stateID = identities.stateID;
    newCarrier.stateIDNumberState = identities.stateIDNumberState;

    const newFUMCarrier = new FUMNewCarrier(newCarrier);

    newFUMCarrier.isPay = this.isPay.value;
    newFUMCarrier.customerName = this.customerName.value;

    this.fumCarrierService.createNewManualFUMCarrier(newFUMCarrier).subscribe(
      (carrier) => {
        this.router.navigateByUrl(`/search/fum-details/${carrier.carrierCode}`);
      },
      (err) => {
        this.serverError = '';

        if (err.error) {
          // Basic error messages:
          if (typeof err.error === 'string') {
            this.serverError = err.error;
          }
          // Complex validation messages:
          else if (typeof err.error === 'object') {
            let errorMessages: string[] = [];

            Object.keys(err.error).forEach(
              (x) => (errorMessages = errorMessages.concat(err.error[x])),
            );

            this.serverError = errorMessages.join('\r\n');
          }
        }

        // Unrecognized error type
        if (this.serverError == '') {
          this.serverError = 'Unexpected server error occurred';
        }
      },
    );
  }
}
