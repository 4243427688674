import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  SimpleChanges,
  Output
} from '@angular/core';
import { AuthService } from '../../../../authentication/_services/auth.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { FUMCarrierDocument } from '../../../models/FUMCarrierDocument';
import { CarrierDocument } from '../../../models/CarrierDocument';
import { FUMCarrierDocumentService } from '../../../services/fum-carrier-document.service';
import { RefDocument } from '../../../../shared/references/RefDocument';
import { RefService } from '../../../../shared/references/ref.service';

@Component({
  selector: 'fum-carrier-documents',
  templateUrl: './fum-carrier-documents.component.html'
})
export class FUMCarrierDocumentComponent implements OnInit, OnChanges {
  @Input() fumCarrierFile: FUMCarrier;
  @Input()
  document: CarrierDocument = null;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  documentForm: FormGroup;
  newDocument: CarrierDocument;
  activeDocument: CarrierDocument;
  addDocument: boolean;
  documentTypeList: RefDocument[];
  documentFileDirty: boolean = false;
  public documentFile: File;

  constructor(
    public fumCarrierDocumentService: FUMCarrierDocumentService,
    public authService: AuthService,
    public refService: RefService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.refService.getCarrierDocumentTypes().subscribe((x) => {
      this.documentTypeList = x;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.fumCarrierFile &&
      changes.fumCarrierFile.currentValue &&
      !changes.fumCarrierFile.previousValue
    ) {
      this.resetForm();
    }
  }

  createNewDocument(): CarrierDocument {
    return {
      carrierDocumentID: 0,
      type: '',
      isActive: true,
      dateUpdated: new Date(),
      dateUpdatedFormatted: '',
      userUpdated: '',
      contentUrl: '',
      note: ''
    };
  }

  resetForm() {
    this.newDocument = this.createNewDocument();
    this.documentFile = null;

    this.addDocument = !this.document;
    this.activeDocument = this.addDocument ? this.newDocument : this.document;
    this.documentFileDirty = false;

    if (this.fumCarrierFile) {
      this.documentForm = new FormGroup({
        note: new FormControl(this.activeDocument.note, Validators.required),
        document: new FormControl(
          this.documentFile,
          this.addDocument ? Validators.required : null
        ),
        refDocumentType: new FormControl(
          this.activeDocument.type,
          Validators.required
        ),
        isActive: new FormControl(this.activeDocument.isActive)
      });
    }
  }

  cancel() {
    this.close.emit();
  }

  save() {
    if (this.documentForm.valid) {
      const formValue = this.documentForm.value;

      let carrierDocument: CarrierDocument = {
        carrierDocumentID: this.activeDocument.carrierDocumentID,
        type: formValue.refDocumentType,
        isActive: formValue.isActive,
        dateUpdated: new Date(),
        dateUpdatedFormatted: '',
        userUpdated: this.authService.userName,
        contentUrl: this.activeDocument.contentUrl,
        note: formValue.note
      };

      if (this.addDocument) {
        this.fumCarrierDocumentService
          .add(this.fumCarrierFile, carrierDocument, this.documentFile)
          .subscribe(
            () => {
              this.toastr.success(
                'FUM Carrier Document Created Successfully!',
                'Success'
              );
              this.close.emit();
            },
            (err) => {
              this.toastr.error(
                `<div class="mt-2" style="width: 100%;">Error: "${
                  err.error
                    ? !err.error.error
                      ? err.error
                      : err.error.error
                    : err.message
                }"<div>`,
                'FUM Carrier Document Could Not Be Created!'
              );
              this.close.emit();
            }
          );
      } else {
        this.fumCarrierDocumentService.update(carrierDocument).subscribe(
          () => {
            this.toastr.success(
              'FUM Carrier Document Updated Successfully!',
              'Success'
            );
            this.close.emit();
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${
                err.error
                  ? !err.error.error
                    ? err.error
                    : err.error.error
                  : err.message
              }"<div>`,
              'FUM Carrier Document Could Not Be Updated!'
            );
            this.close.emit();
          }
        );
      }
    }
  }

  onFileSelect(event: any) {
    this.documentFile = event.addedFiles[0];
    this.documentForm.controls.document.setValue(this.documentFile);
    this.documentFileDirty = true;
  }

  onRemove() {
    this.documentFile = null;
    this.documentForm.controls.document.setValue(null);
  }

  get selectedDocument(): AbstractControl {
    return this.documentForm.get('document');
  }
}
