import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { parseDate } from '@telerik/kendo-intl';
import { PortfolioSearchResult } from '../models/PortfolioSearchResult';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../../shared/configuration/Configuration';
import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';

@Injectable()
export class PortfolioService {
  public portfolioRep: string;
  public portfolioRepChangedBehavior: BehaviorSubject<string>;
  config: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private authService: AuthService
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
    this.initUser();
    this.portfolioRepChangedBehavior = new BehaviorSubject<string>(
      this.portfolioRep
    );
  }

  private canViewFUMCarrierFile(): boolean {
    return this.authService.can(Permissions.ViewFUMCarrierFile);
  }

  public getPortFolio(): Observable<PortfolioSearchResult[]> {
    let url = `${this.config.carrierServiceUrl}search/following`;

    if (this.portfolioRep) {
      url += `?userName=${this.portfolioRep}`;
    }

    if (this.canViewFUMCarrierFile()) {
      url += url.indexOf('?') > -1 ? '&' : '?';

      url += 'includeFUMCarriers=true';
    }

    return this.http.get<PortfolioSearchResult[]>(url).pipe(
      map((x) => {
        x.forEach((c) => {
          c.lastContacted = parseDate('' + c.lastContacted);
        });
        return x;
      })
    );
  }

  public portfolioRepChanged(rep: string) {
    this.portfolioRep = rep;
    this.portfolioRepChangedBehavior.next(rep);
  }

  private initUser() {
    var appUser = localStorage.getItem('portfolioRep');
    this.portfolioRep = appUser ? JSON.parse(appUser).appUserName : null;
  }
}
