import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CarrierErrors } from '../CarrierErrors';
import { DetailsService } from '../../services/details.service';
import { CarrierFileDetails } from '../../models/CarrierFileDetails';
import { CommonService } from '../../../Services/common.service';
import { AuthService } from '../../../authentication/_services/auth.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { AppState } from '../../../appstate.model';
import { UpdateCarrierDetails } from '../../actions/carrier-details.actions';
import { CarrierStatus } from '../../models/CarrierStatus';
import { HttpErrorResponse } from '@angular/common/http';
import { CarrierOfferComponent } from '../../../shared/carrier-offer/carrier-offer.component';
import {
  DialogCloseResult,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { CarrierOfferService } from '../../../shared/carrier-offer/carrier-offer.service';
import * as moment from 'moment';
import { CarrierOfferDC } from '../../../shared/carrier-offer/CarrierOfferDC';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CarrierSyncService } from '../../services/carrier-sync.service';
import { Configuration } from '../../../shared/configuration/Configuration';

@Component({
  selector: 'truckload-carrier-details',
  templateUrl: './carrier-details.component.html',
  styleUrls: ['./carrier-details.component.scss'],
})
export class CarrierDetailsComponent implements OnInit {
  config: Configuration;
  public loading: boolean;
  @Input()
  carrierCode: string;

  @Output()
  followingToggleChanged: EventEmitter<any> = new EventEmitter();
  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  isAuthorizedCarrier: boolean = false;
  carrierFileDetails: CarrierFileDetails;
  carrierStatus: CarrierStatus;
  errorMessages = [];
  isErrorWindowOpen = false;
  errorRedirect = false;

  constructor(
    public detailsService: DetailsService,
    public carrierSyncService: CarrierSyncService,
    public commonService: CommonService,
    public authService: AuthService,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private carrierOfferService: CarrierOfferService,
    private notificationService: NotificationService,
  ) {
    store
      .select((x) => x.CarrierDetails.status)
      .subscribe((x) => {
        this.carrierStatus = x;
      });

    store
      .select((x) => x.CarrierDetails.details)
      .subscribe((x) => {
        if (x) {
          this.carrierFileDetails = x;
          this.isAuthorizedCarrier = x.isAuthorizedCarrier;
        }
      });

    store
      .select((x) => x.CarrierDetails.loading)
      .subscribe((x) => {
        this.loading = x;
      });

    this.store
      .select((x) => x.CarrierIdentity.carrierName)
      .subscribe((x) => {
        if (this.carrierFileDetails && !(x instanceof HttpErrorResponse)) {
          this.carrierFileDetails.name = x.name;
          this.carrierFileDetails.dbaName = x.dbaName;
        }
      });
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  ngOnInit() { }

  get canViewPostMatch(): boolean {
    return this.authService.can(Permissions.ViewPostMatch);
  }

  get canCreateOfferFromDetails(): boolean {
    return this.authService.can(Permissions.CreateOfferFromDetails);
  }

  get canFollowCarrier(): boolean {
    return this.authService.can(Permissions.ViewFollowCarrier);
  }

  get canEditComCheckAuthorization(): boolean {
    return this.authService.can(Permissions.EditComCheckAuthorization);
  }

  syncDisabled(): boolean {
    return this.config.disableCarrierSync === 'true';
  }

  onPostMatchClick(carrierCode: string) {
    var postMatchUrl =
      window.location.origin + `/carrier/postmatch/${carrierCode}`;
    window.open(postMatchUrl);
  }

  getCertifiedStatus(isCertified: boolean): string {
    return isCertified ? 'Certified' : 'Uncertified';
  }

  getManualyMonitoredStatus(isManualyMonitored: boolean): string {
    return isManualyMonitored ? 'Yes' : 'No';
  }

  onContactSaved(event): void {
    if (event && event.isPrimary) {
      this.carrierFileDetails.primaryContact = event.name;
      this.carrierFileDetails.phone = event.phone;
      this.carrierFileDetails.email = event.email;
    } else {
      this.onContactRemoved(event);
    }
  }

  onIdentitiesSaved(event): void {
    if (event) {
      this.carrierFileDetails.mcNumber = event.mcNumber;
      this.carrierFileDetails.dotNumber = event.dotNumber;
      this.carrierFileDetails.scac = event.scac;
    }
  }

  onStatusSaved(event): void {
    this.carrierFileDetails.status = event;
  }

  onContactRemoved(event): void {
    this.carrierFileDetails.primaryContact = '';
    this.carrierFileDetails.phone = '';
    this.carrierFileDetails.email = '';
  }

  onFollowingToggleChanged() {
    this.followingToggleChanged.emit();
  }

  private showOfferDialog(): void {
    const offerRef = this.dialogService.open({
      content: CarrierOfferComponent,
      width: 800,
    });

    this.carrierOfferService
      .getCarrierSummary(this.carrierCode)
      .toPromise()
      .then((carrierSummary) => {
        const instance: CarrierOfferComponent = offerRef.content
          .instance as CarrierOfferComponent;
        instance.carrierSummary = carrierSummary;

        offerRef.result.toPromise().then((dialogResult: any) => {
          if (!dialogResult.result) {
            return;
          }

          const effectiveDate = moment(new Date()).format('L');
          const currencyCode = dialogResult.result.currencyCode;

          Promise.all([
            this.carrierOfferService.getRate(effectiveDate, currencyCode),
            this.carrierOfferService.getUserDetails().toPromise(),
          ])
            .then((results) => {
              const rate = results[0];
              const userDetails = results[1];
              const convertedBuyRate = dialogResult.result.buyRate / rate;
              let offer = {
                carrierCode: carrierSummary.carrierCode,
                carrierName: carrierSummary.name,
                dotNumber: carrierSummary.dotNumber,
                mcNumber: carrierSummary.mcNumber,
                scac: carrierSummary.scac,
                currencyCode: currencyCode,
                carrierChargeInUSD: convertedBuyRate,
                carrierCharge: dialogResult.result.buyRate,
                customerCharge: 0,
                notes: dialogResult.result.quoteNote,
              } as CarrierOfferDC;

              return this.carrierOfferService
                .createOffer(dialogResult.result.shipmentID, userDetails, offer)
                .toPromise();
            })
            .then(
              (result) => {
                this.showOfferCreatedNotification(result as CarrierOfferDC);
              },
              (err) => {
                this.showOfferErrorNotification(err);
              },
            );
        });
      });
  }

  private showOfferCreatedNotification(offer: CarrierOfferDC): void {
    this.notificationService.show({
      content: 'Offer has been created for ' + offer.carrierName,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 3000 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'info', icon: true },
    });
  }

  private showOfferErrorNotification(err: any): void {
    this.notificationService.show({
      content: `Error creating offer: ${err.message}`,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 3000 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'error', icon: true },
    });
  }

  public toggleComCheck() {
    this.carrierFileDetails.isAuthorizedCarrier = this.isAuthorizedCarrier;
    this.store.dispatch(new UpdateCarrierDetails(this.carrierFileDetails));
  }
  get dotNumber() {
    return this.carrierFileDetails.dotNumber;
  }

  get mcNumber() {
    return this.carrierFileDetails.mcNumber;
  }

  confirmSync() {
    this.dialogService
      .open({
        title: 'Confirm',
        content: 'Are you sure you want to sync this carrier?',
        actions: [{ text: 'Yes', primary: true }, { text: 'No' }],
        width: 450,
        height: 200,
        minWidth: 250,
      })
      .result.subscribe((res) => {
        if (res instanceof DialogCloseResult) {
          return;
        }
        if (res.text === 'Yes') {
          this.syncCarrierDetails();
        }
      });
  }

  syncCarrierDetails() {
    let status = this.carrierFileDetails.isCertified
      ? 'needs_to_onboard_but_can_remain_active'
      : 'needs_to_onboard';

    let mc = this.mcNumber.replace(/[^0-9]/g, '');
    let appUser = this.authService.loggedAppUser;
    this.carrierSyncService
      .syncHighwayCarrier(this.dotNumber, mc, status, appUser.email)
      .subscribe(
        (result) => {
          this.notificationService.show({
            content: `Carrier sync details: ${result} `,
            cssClass: 'button-notification',
            animation: { type: 'fade', duration: 3000 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'info', icon: true },
          });
        },
        (err) => {
          this.notificationService.show({
            content: `Error syncing carrier details: ${err.message}`,
            cssClass: 'button-notification',
            animation: { type: 'fade', duration: 3000 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
          });
        },
      );
  }
}
