import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CarrierInspection } from './CarrierInspection';
import { Configuration } from '../../../shared/configuration/Configuration';
import { AppState } from '../../../appstate.model';

@Injectable()
export class InspectionService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getInspections(carrierCode: string): Observable<CarrierInspection[]> {
    return this.http.get<CarrierInspection[]>(
      `${this.config.carrierServiceUrl}${carrierCode}/inspection`,
    );
  }
}
