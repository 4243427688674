import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CarrierFileDetails } from '../models/CarrierFileDetails';
import { Cacheable } from 'ngx-cacheable';
import { Configuration } from '../../../shared/configuration/Configuration';
import { Store } from '@ngrx/store';
import { AppState } from '../../../appstate.model';

@Injectable()
export class PostMatchDetailsService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  @Cacheable()
  getCarrierFileDetails(carrierCode: string): Observable<CarrierFileDetails> {
    return this.http.get<CarrierFileDetails>(
      `${this.config.carrierServiceUrl}${carrierCode}/summary`,
    );
  }
}
