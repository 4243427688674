import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { PageService } from './Services/page.service';
import { AppInsightService } from './app.insight.service';
import { filter } from 'rxjs/operators';

@Component({
  // prevent style encapsulation
  encapsulation: ViewEncapsulation.None,
  selector: 'truckload-root',
  template: `
    <div class="container-fluid">
      <ng-container *ngIf="isAuthRoute">
        <truckload-header></truckload-header>
        <breadcrumb></breadcrumb>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  `,
  providers: [PageService],
})
export class AppComponent {
  isAuthRoute = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.isAuthRoute = this.checkIsAuthRoute();
      });
  }

  private checkIsAuthRoute(): boolean {
    const root = this.router.routerState.snapshot.root;
    return !(
      root.firstChild &&
      root.firstChild.data &&
      root.firstChild.data.isPublic
    );
  }
}
