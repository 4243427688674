import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { Geolocation } from '../../../../shared/Models/Geolocation';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressService } from '../../../../carrier/carrier-file/carrier-addresses/address.service';
import { ToastrService } from 'ngx-toastr';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FUMCarrierService } from '../../../../Services/fum-carrier.service';
import { FUMCarrierAddressService } from '../../../../carrier/services/fum-carrier-address.service';
import { CarrierAddress } from './../../../models/CarrierAddress';
import { Permissions } from '../../../../shared/Enums/Permissions';
import { GoogleAddressLookupComponent } from '../../../../shared/google-address-lookup/google-address-lookup.component';

@Component({
  selector: 'fum-carrier-addresses',
  templateUrl: './fum-carrier-addresses.component.html',
  styleUrls: ['./fum-carrier-addresses.component.scss']
})
export class FUMCarrierAddressesComponent implements OnInit {
  @ViewChild('googleAddressLookup', { static: false })
  googleAddressLookup: GoogleAddressLookupComponent;
  @ViewChild('addressGrid', { static: true })
  public addressGrid: GridComponent;
  @Input()
  carrierCode: string;
  @Input()
  address: CarrierAddress = null;
  @Input()
  fumCarrierFile: FUMCarrier;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  addressTypes: string[];
  activeAddress: CarrierAddress = null;
  addressForm: FormGroup;
  newAddress: CarrierAddress;
  geolocation: Geolocation = {} as Geolocation;
  addAddress = true;
  canEditType = false;
  manualLocationChecked = false;

  constructor(
    public addressService: AddressService,
    public authService: AuthService,
    public fumCarrierService: FUMCarrierService,
    public fumCarrierAddressService: FUMCarrierAddressService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loadAddressTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.address ||
      (changes.fumCarrierFile &&
        changes.fumCarrierFile.currentValue &&
        !changes.fumCarrierFile.previousValue)
    ) {
      if (this.fumCarrierFile) this.resetForm();
    }
  }

  private createNewAddress(values: any = null): CarrierAddress {
    return {
      carrierAddressID: this.address ? this.address.carrierAddressID : 0,
      carrierID: null,
      fumCarrierID: this.fumCarrierFile.carrierID,
      addressID: 0,
      type: values ? values.type : '',
      companyName: '',
      addressLine1: values ? values.addressLine1 : '',
      city: values ? values.city : '',
      stateProvince: values ? values.stateProvince : '',
      postalCode: values ? values.postalCode : '',
      countryCode: 'USA'
    } as CarrierAddress;
  }

  public resetForm(): void {
    this.newAddress = this.createNewAddress();

    this.addAddress = !this.address;
    this.activeAddress = this.addAddress ? this.newAddress : this.address;
    this.canEditType = this.activeAddress.type !== 'Main';
    this.manualLocationChecked = !this.addAddress;

    if (this.googleAddressLookup) {
      this.googleAddressLookup.clearInput();
    }

    if (this.activeAddress) {
      this.addressForm = new FormGroup({
        type: new FormControl(this.activeAddress.type, Validators.required),
        addressLine1: new FormControl(
          this.activeAddress.addressLine1,
          Validators.required
        ),
        city: new FormControl(this.activeAddress.city, Validators.required),
        stateProvince: new FormControl(this.activeAddress.stateProvince, [
          Validators.required,
          Validators.minLength(2)
        ]),
        postalCode: new FormControl(
          this.activeAddress.postalCode,
          Validators.required
        )
      });
    }
  }

  private loadAddressTypes(): void {
    this.addressService.getAddressTypes().subscribe(
      (data) => {
        this.addressTypes = data;
      },
      (err) => {
        this.toastr.error(
          `<div class="mt-2" style="width: 100%;">Error: "${
            err.error
              ? !err.error.error
                ? err.error
                : err.error.error
              : err.message
          }"<div>`,
          `FUM Carrier Address Types Could Not Be Populated!`
        );
      }
    );
  }

  setLocation(): void {
    this.addressForm.patchValue({
      city: this.geolocation.city
        ? this.geolocation.city
        : this.activeAddress.city,
      stateProvince: this.geolocation.stateProvince
        ? this.geolocation.stateProvince
        : this.activeAddress.stateProvince,
      postalCode: this.geolocation.postalCode
        ? this.geolocation.postalCode
        : this.activeAddress.postalCode
    });
    this.activeAddress.countryCode = this.geolocation.countryCode
      ? this.geolocation.countryCode
      : this.activeAddress.countryCode;
  }

  submit(): void {
    let carrierAddress: CarrierAddress = this.createNewAddress(
      this.addressForm.value
    );

    if (this.addAddress) {
      this.fumCarrierAddressService.create(carrierAddress).subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Address Created Successfully!',
            'Success'
          );
          this.close.emit();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            'FUM Carrier Address Could Not Be Created!'
          );
          this.close.emit();
        }
      );
    } else {
      this.fumCarrierAddressService.update(carrierAddress).subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Address Updated Successfully!',
            'Success'
          );
          this.close.emit();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            'FUM Carrier Address Could Not Be Updated!'
          );
          this.close.emit();
        }
      );
    }
  }

  cancel(): void {
    this.close.emit();
  }

  public get availableAddressTypes() {
    let values = this.addressTypes ? this.addressTypes : [];

    if (this.fumCarrierFile.carrierAddresses && this.addressTypes) {
      values = this.addressTypes.filter((type) => {
        return !this.fumCarrierFile.carrierAddresses.find(
          (address) => address.type === type
        );
      });
    }

    return values;
  }

  get fumCarrierAddressArray() {
    return [this.activeAddress];
  }

  get canEditManualLocation(): boolean {
    return this.authService.can(Permissions.EditManualLocation);
  }
}
