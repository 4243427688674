import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  DropDownsModule,
  DropDownListModule,
  MultiSelectModule,
  ComboBoxModule,
  AutoCompleteModule,
} from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';

// admin imports
import { NewAdminRoutingModule } from './admin-routing.module';
// components
import { AdminComponent } from './admin.component';
import { UsersComponent } from './users/users.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateEditComponent } from './templates/template-edit/template-edit.component';
// services
import { AdminService } from './admin.service';
import { UserService } from './users/users.service';
import { TemplatesService } from './templates/templates.service';
import { StoreModule } from '@ngrx/store';
import { appReducer } from '../app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '../app.effects';

@NgModule({
  declarations: [
    AdminComponent,
    UsersComponent,
    UserEditComponent,
    TemplatesComponent,
    TemplateEditComponent,
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    DropDownListModule,
    MultiSelectModule,
    ComboBoxModule,
    AutoCompleteModule,
    GridModule,
    DialogsModule,
    DatePickerModule,
    InputsModule,
    LayoutModule,
    NewAdminRoutingModule,
    StoreModule.forFeature('Configuration', appReducer),
    EffectsModule.forFeature([AppEffects]),
  ],
  providers: [AdminService, UserService, TemplatesService],
})
export class NewAdminModule {}
