import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { parseDate } from '@telerik/kendo-intl';
import { Store } from '@ngrx/store';
import { CarrierLocationSearchParameters } from '../models/CarrierLocationSearchParameters';
import { CarrierSearchResult } from '../models/CarrierSearchResults';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';

@Injectable()
export class LocationSearchService {
  config: Configuration;
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getByLocation(
    params: CarrierLocationSearchParameters,
  ): Observable<CarrierSearchResult[]> {
    const url = `${this.config.carrierServiceUrl}search`;

    const query = {
      originLatitude: params.Address.latitude,
      originLongitude: params.Address.longitude,
      originRadius: params.Radius,
      truckTypes: params.TruckTypes,
      serviceTypes: params.ServiceTypes,
      certificationTypes: params.CertificationTypes,
      insuranceValue: params.InsuranceValue,
    };

    return this.httpClient.post<CarrierSearchResult[]>(url, query).pipe(
      map((x) => {
        x.map((c) => {
          c.lastContacted = parseDate('' + c.lastContacted);
          if (c.stateCarrierID) {
            c.stateCarrierID = `${c.stateCode} ${c.stateCarrierID}`;
          } else {
            c.stateCarrierID = '';
          }
        });
        return x;
      }),
    );
  }
}
