import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppUser } from './AppUser';
import { Store } from '@ngrx/store';
import { AppState } from '../../appstate.model';
import { Configuration } from '../configuration/Configuration';

@Injectable()
export class AppUserService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  search(filter: string): Observable<AppUser[]> {
    return this.http
      .get<
        AppUser[]
      >(`${this.config.carrierServiceUrl}user/search?filter=${filter}`)
      .pipe(
        map((x) => {
          const users = [];

          x.forEach((y) => {
            users.push({
              appUserID: y.appUserID,
              appUserName: y.appUserName,
              name: y.firstName + ' ' + y.lastName,
              isRep: false,
            });
          });

          return users;
        }),
      );
  }

  public getSalesReps(): Observable<AppUser[]> {
    return this.http
      .get<AppUser[]>(`${this.config.carrierServiceUrl}user/carrierReps`)
      .pipe(
        map((x) => {
          const users = [];

          x.forEach((y) => {
            users.push({
              appUserID: y.appUserID,
              appUserName: y.appUserName,
              name: y.firstName + ' ' + y.lastName,
              isRep: false,
            });
          });
          // save to session storage
          this.salesReps = users;
          return users;
        }),
      );
  }

  set salesReps(value: AppUser[]) {
    if (value) {
      sessionStorage.setItem('salesReps', JSON.stringify(value));
    } else {
      sessionStorage.removeItem('salesReps');
    }
  }

  get salesReps(): AppUser[] {
    const salesReps = sessionStorage.getItem('salesReps');

    if (salesReps) {
      return Object.assign([] as AppUser[], JSON.parse(salesReps));
    } else {
      return null;
    }
  }
}
