import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Configuration } from '../../../app/shared/configuration/Configuration';
import { AppState } from '../../../app/appstate.model';
import { RefIdentityDC } from '../dto/RefIdentityDC';

@Injectable()
export class FUMCarrierIdentityService {
  config: Configuration;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  getRefIdentities(): Observable<RefIdentityDC[]> {
    return new Observable<RefIdentityDC[]>((observer) => {
      this.http
        .get<
          RefIdentityDC[]
        >(`${this.config.carrierServiceUrl}reference/identity`)
        .subscribe(
          (x) => {
            observer.next(x);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          },
        );
    });
  }
}
