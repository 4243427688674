export enum Permissions {
  // can manage
  ManageChannels = 'canManageChannels',
  ManagePermissions = 'canManagePermissions',
  ManageTeams = 'canManageTeams',
  ManageUsers = 'canManageUsers',
  ManageUserPermissions = 'canManageUserPermissions',
  ManageNotes = 'canManageNotes',
  ManageAllNotes = 'canManageAllNotes',

  // can create
  CreateManualCarrier = 'canCreateManualCarrier',
  CreateOffer = 'canCreateOffer',
  CreateOfferFromDetails = 'canCreateOfferFromDetails',

  // can view
  ViewAddress = 'canViewAddress',
  ViewAdmin = 'canViewAdmin',
  ViewBGStatus = 'canViewBGStatus',
  ViewBillingAddress = 'canViewBillingAddress',
  ViewCarrier = 'canViewCarrier',
  ViewCarrierFile = 'canViewCarrierFile',
  ViewFUMCarrierFile = 'canViewFUMCarrierFile',
  ViewCertifications = 'canViewCertifications',
  ViewChannels = 'canViewChannels',
  ViewDefaults = 'canViewDefaults',
  ViewDocuments = 'canViewDocuments',
  ViewEquipment = 'canViewEquipment',
  ViewHistory = 'canViewHistory',
  ViewInspections = 'canViewInspections',
  ViewInsurance = 'canViewInsurance',
  ViewLaneLoadHistory = 'canViewLaneLoadHistory',
  ViewLanes = 'canViewLanes',
  ViewManualCarrier = 'canViewManualCarrier',
  ViewNotes = 'canViewNotes',
  ViewPermissions = 'canViewPermissions',
  ViewTeams = 'canViewTeams',
  ViewUserPermissions = 'canViewUserPermissions',
  ViewUsers = 'canViewUsers',
  ViewIDSearch = 'canViewIDSearch',
  ViewNameSearch = 'canViewNameSearch',
  ViewLaneSearch = 'canViewLaneSearch',
  ViewLocationSearch = 'canViewLocationSearch',
  ViewPostMatch = 'canViewPostMatch',
  ViewFollowCarrier = 'canViewFollowCarrier',
  ViewSalesRepSearch = 'canViewSalesRepSearch',
  ViewTruckPostingSearch = 'canViewTruckPostingSearch',
  ViewCRMLeadStatus = 'canViewCRMLeadStatus',
  ViewLoadHistory = 'canViewLoadHistory',

  // can edit

  EditAddress = 'canEditAddress',
  EditAssociations = 'canEditAssociations',
  EditBillingAddress = 'canEditBillingAddress',
  EditBGStatus = 'canEditBGStatus',
  EditCargo = 'canEditCargo',
  EditCertifications = 'canEditCertifications',
  EditContacts = 'canEditContacts',
  EditContactVerification = 'canEditContactVerification',
  EditDefaults = 'canEditDefaults',
  EditEquipment = 'canEditEquipment',
  EditCarrierIDs = 'canEditCarrierIDs',
  EditInsurance = 'canEditInsurance',
  EditInventory = 'canEditInventory',
  EditLanes = 'canEditLanes',
  EditManualCarrier = 'canEditManualCarrier',
  EditManuallyMonitored = 'canEditManuallyMonitored',
  EditNotes = 'canEditNotes',
  EditPostings = 'canEditPostings',
  EditServices = 'canEditServices',
  EditCarrierDocment = 'canEditCarrierDocument',
  EditManualLocation = 'canOverrideCarrierLocation',
  EditComCheckAuthorization = 'canEditComCheckAuthorization',
  EditTrackingPreference = 'canEditTrackingPreference',
  EditTruckerTools = 'canEditTruckerTools',
  EditCRMLeadStatus = 'canEditCRMLeadStatus',

  // delete
  DeleteAssociations = 'canDeleteAssociations',
  DeleteCarrierContact = 'canDeleteCarrierContact',
  DeleteDocuments = 'canDeleteDocuments',
  DeleteManualCarrier = 'canDeleteManualCarrier',
  DeleteCarrierDocment = 'canDeleteCarrierDocument',
  DeleteInsurance = 'canDeleteInsurance',
  DeleteLanes = 'canDeleteLanes',

  // misc
  UploadDocuments = 'canUploadDocuments',
  UploadOtherTypeDocuments = 'canUploadOtherTypeDocuments',
}
