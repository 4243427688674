import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CarrierAssociation } from '../../../models/CarrierAssociation';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FUMCarrierAppUserRelationService } from '../../../services/fum-carrier-association.service';

@Component({
  selector: 'fum-carrier-appuser-relation-component',
  templateUrl: './fum-carrier-appuser-relation.component.html',
})
export class FUMCarrierAppUserRelationComponent implements OnInit {
  @Input()
  fumCarrierFile: FUMCarrier;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  associationForm: FormGroup;
  newAssociation: CarrierAssociation;
  isMainRep: boolean = false;
  showAppUserAutocomplete: boolean = false;

  constructor(
    private fumCarrierAppUserRelationService: FUMCarrierAppUserRelationService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.resetForm();
  }

  createNewAssociation(): CarrierAssociation {
    return {
      appUserID: null,
      carrierID: null,
      firstName: null,
      lastName: null,
      isRep: false,
    };
  }

  public resetForm(): void {
    this.newAssociation = this.createNewAssociation();
    this.showAppUserAutocomplete = true;

    this.associationForm = new FormGroup({
      appUserID: new FormControl(
        this.newAssociation.appUserID,
        Validators.required,
      ),
      isRep: new FormControl(this.newAssociation.isRep, Validators.required),
    });
  }

  selectAppUser($event) {
    this.newAssociation.appUserID = $event.appUser.appUserID;
    this.associationForm.controls.appUserID.setValue($event.appUser.appUserID);
  }

  submit(): void {
    this.newAssociation.appUserID =
      this.associationForm.controls.appUserID.value;
    this.newAssociation.isRep = this.associationForm.controls.isRep.value;
    this.newAssociation.carrierID = this.fumCarrierFile.carrierID;

    this.fumCarrierAppUserRelationService
      .insertUpdateCarrierAssociation(
        this.fumCarrierFile.carrierCode,
        this.newAssociation,
      )
      .subscribe(
        () => {
          this.toastr.success(
            'FUM Carrier Association Created Successfully!',
            'Success',
          );
          this.cancel();
        },
        (err) => {
          this.toastr.error(
            `<div class="mt-2" style="width: 100%;">Error: "${
              err.error
                ? !err.error.error
                  ? err.error
                  : err.error.error
                : err.message
            }"<div>`,
            'FUM Carrier Association Could Not Be Created!',
          );
          this.cancel();
        },
      );
  }

  cancel(): void {
    this.close.emit();
    this.showAppUserAutocomplete = false;
  }

  get fumCarrierAssociationArray() {
    return [this.newAssociation];
  }
}
