import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AuthService } from '../../../../authentication/_services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FUMCarrier } from '../../../models/FUMCarrier';
import { ToastrService } from 'ngx-toastr';
import { FUMCarrierContactService } from '../../../services/fum-carrier-contact.service';
import { CarrierContact } from '../../../models/CarrierContact';
import { RefContact } from '../../../../shared/references/RefContact';
import { RefService } from '../../../../shared/references/ref.service';
import { notDefaultObjectValidator } from '../../../../shared/validators/defaultValue.validator';
import { emailRequiredValidator } from '../../../../shared/validators/email.validator';
import {
  CarrierContactVerificationStatus,
  CarrierContactVerificationStatusList
} from '../../../models/CarrierContact';
import { Permissions } from '../../../../shared/Enums/Permissions';

@Component({
  selector: 'fum-carrier-contacts',
  templateUrl: './fum-carrier-contacts.component.html',
  styleUrls: ['./fum-carrier-contacts.component.scss']
})
export class FUMCarrierContactsComponent implements OnInit, OnChanges {
  @Input() fumCarrierFile: FUMCarrier;
  @Input() contact: CarrierContact = null;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  contactForm: FormGroup;
  activeContact: CarrierContact;
  newContact: CarrierContact;
  contactTypeList: RefContact[];
  defaultRefContact: RefContact = { refContactID: 0, type: 'Select Type...' };
  addContact: boolean = true;

  verificationStatusList = CarrierContactVerificationStatusList;

  constructor(
    public fumCarrierContactService: FUMCarrierContactService,
    private refService: RefService,
    public authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.refService.getContactTypes().subscribe((data) => {
      this.contactTypeList = data;
      this.resetForm();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contact) {
      this.resetForm();
    }
  }

  private createNewContact(): CarrierContact {
    return {
      carrierContactID: this.activeContact
        ? this.activeContact.carrierContactID
        : null,
      carrierID: null,
      fumCarrierID: this.fumCarrierFile.carrierID,
      carrierCode: null,
      contactID: null,
      refContactID: null,
      isPrimary: false,
      name: '',
      title: '',
      phone: '',
      cell: '',
      fax: '',
      email: '',
      isAuthorizedContact: false,
      originationSource: 'Internal',
      contactType: '',
      verificationStatus: CarrierContactVerificationStatus.Verified
    } as CarrierContact;
  }

  public resetForm() {
    if (!this.fumCarrierFile) {
      setTimeout(() => {
        this.resetForm();
      }, 1000);
      return;
    }

    this.newContact = this.createNewContact();

    this.addContact = !this.contact;
    this.activeContact = this.addContact ? this.newContact : this.contact;

    if (!this.addContact) {
      this.activeContact.carrierContactID = this.contact.carrierContactID;
      this.activeContact.contactID = this.contact.contactID;
      this.activeContact.refContactID = this.contact.refContactID;
    }

    let refContact = this.contactTypeList
      ? this.contactTypeList.find(
          (x) => x.type === this.activeContact.contactType
        )
      : undefined;

    if (this.activeContact) {
      this.contactForm = new FormGroup({
        name: new FormControl(this.activeContact.name, Validators.required),
        title: new FormControl(this.activeContact.title),
        isPrimary: new FormControl(this.activeContact.isPrimary),
        isAuthorizedContact: new FormControl(
          this.activeContact.isAuthorizedContact || false
        ),
        refContact: new FormControl(
          refContact ? refContact : this.defaultRefContact,
          [
            Validators.required,
            notDefaultObjectValidator(this.defaultRefContact)
          ]
        ),
        phone: new FormControl({
          value: this.activeContact.phone,
          disabled: false
        }),
        cell: new FormControl({
          value: this.activeContact.cell,
          disabled: false
        }),
        fax: new FormControl({
          value: this.activeContact.fax,
          disabled: false
        }),
        email: new FormControl(this.activeContact.email, [
          emailRequiredValidator()
        ]),
        verificationStatus: new FormControl({
          value: this.activeContact.verificationStatus,
          disabled: true // This is now a read only field
        })
      });
    }
  }

  cancel() {
    this.resetForm();

    this.close.emit();
  }

  save() {
    if (this.contactForm.valid) {
      const phoneMask = /(\d{3})(\d{3})(\d{4})/;
      const formValue = this.contactForm.value;

      this.activeContact = {
        ...this.activeContact,
        name: formValue.name,
        title: formValue.title,
        phone: formValue.phone
          ? formValue.phone.replace(phoneMask, '($1) $2-$3')
          : '',
        cell: formValue.cell
          ? formValue.cell.replace(phoneMask, '($1) $2-$3')
          : '',
        fax: formValue.fax
          ? formValue.fax.replace(phoneMask, '($1) $2-$3')
          : '',
        email: formValue.email,
        isPrimary: formValue.isPrimary,
        isAuthorizedContact: formValue.isAuthorizedContact,
        contactType: formValue.refContact.type,
        refContactID: formValue.refContact.refContactID,
        verificationStatus: formValue.verificationStatus
          ? formValue.verificationStatus
          : this.activeContact.verificationStatus
      };

      if (this.activeContact.isPrimary && !this.activeContact.email) {
        this.toastr.error(
          'Cannot remove email address from Primary contact',
          'Carrier Contact - Validation Error'
        );
        return;
      }

      if (this.addContact) {
        this.fumCarrierContactService.create(this.activeContact).subscribe(
          () => {
            this.toastr.success(
              'FUM Carrier Contact Created Successfully!',
              'Success'
            );
            this.close.emit();
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${
                err.error
                  ? !err.error.error
                    ? err.error
                    : err.error.error
                  : err.message
              }"<div>`,
              'FUM Carrier Contact Could Not Be Created!'
            );
            this.close.emit();
          }
        );
      } else {
        this.fumCarrierContactService.update(this.activeContact).subscribe(
          () => {
            this.toastr.success(
              'FUM Carrier Contact Updated Successfully!',
              'Success'
            );
            this.close.emit();
          },
          (err) => {
            this.toastr.error(
              `<div class="mt-2" style="width: 100%;">Error: "${
                err.error
                  ? err.error.title
                    ? err.error.title
                    : !err.error.error
                      ? err.error
                      : err.error.error
                  : err.message
              }"<div>`,
              'FUM Carrier Contact Could Not Be Updated!'
            );
            this.close.emit();
          }
        );
      }
    }
  }

  get canEditPrimaryContactStatus(): boolean {
    return this.authService.can(Permissions.EditContactVerification);
  }
}
