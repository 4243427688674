import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { CarrierInsurance } from '../models/CarrierInsurance';

@Injectable()
export class FUMCarrierInsuranceService {
  config: Configuration;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  create(carrierInsurance: CarrierInsurance): Observable<CarrierInsurance> {
    return this.http.post<CarrierInsurance>(
      `${this.config.carrierServiceUrl}FUM/CarrierInsurance`,
      carrierInsurance,
    );
  }

  update(carrierInsurance: CarrierInsurance): Observable<CarrierInsurance> {
    return this.http.put<CarrierInsurance>(
      `${this.config.carrierServiceUrl}FUM/CarrierInsurance/${carrierInsurance.carrierInsuranceID}`,
      carrierInsurance,
    );
  }

  delete(carrierInsuranceID: number): Observable<any> {
    return this.http.delete(
      `${this.config.carrierServiceUrl}FUM/CarrierInsurance/${carrierInsuranceID}`,
    );
  }
}
