import { OnInit, Component, TemplateRef } from '@angular/core';
import { TemplatesService } from './templates.service';
import { Template } from './Template';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'admin-templates',
  templateUrl: './templates.component.html',
})
export class TemplatesComponent implements OnInit {
  allTemplates: Template[];

  dialog: DialogRef;
  templateToDelete: Template;

  constructor(
    private templateService: TemplatesService,
    private dialogService: DialogService,
  ) {
    templateService.getAll().subscribe((x) => {
      this.allTemplates = x;
    });
  }

  ngOnInit(): void {}

  public showConfirm(
    template: Template,
    title: string,
    bodyTemplate: TemplateRef<any>,
  ) {
    this.templateToDelete = template;
    this.dialog = this.dialogService.open({
      width: 500,
      height: 320,
      title: title,
      content: bodyTemplate,
    });
  }

  public delete() {
    this.templateService.delete(this.templateToDelete).subscribe((x) => {
      this.allTemplates = x;
      this.dialog.close();
    });
  }

  public cancel() {
    this.dialog.close();
  }

  public templateUpdated(newTemplates: Template[]) {
    this.allTemplates = newTemplates;
  }
}
