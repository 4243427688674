import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { parseDate } from '@telerik/kendo-intl';
import { Store } from '@ngrx/store';
import { CarrierSearchResult } from '../models/CarrierSearchResults';
import { Configuration } from '../../shared/configuration/Configuration';
import { AppState } from '../../appstate.model';
import { AuthService } from '../../authentication/_services/auth.service';
import { Permissions } from '../../shared/Enums/Permissions';

@Injectable()
export class NameSearchService {
  config: Configuration;
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private authService: AuthService
  ) {
    this.store
      .select((x) => x.Configuration)
      .subscribe((x) => {
        this.config = x;
      });
  }

  private canViewFUMCarrierFile(): boolean {
    return this.authService.can(Permissions.ViewFUMCarrierFile);
  }

  getByName(carrierName: string): Observable<CarrierSearchResult[]> {
    let url = `${this.config.carrierServiceUrl}search/name?substring=${carrierName}`;

    if (this.canViewFUMCarrierFile()) {
      url += '&includeFUMCarriers=true';
    }

    return this.httpClient.get<CarrierSearchResult[]>(url).pipe(
      map((x) => {
        x.map((c) => {
          c.lastContacted = parseDate('' + c.lastContacted);
          if (c.stateCarrierID) {
            c.stateCarrierID = `${c.stateCode} ${c.stateCarrierID}`;
          } else {
            c.stateCarrierID = '';
          }
        });
        return x;
      })
    );
  }
}
